import React, { useEffect, useRef, useState } from "react";
import Countup from "react-countup";
import { Link } from "react-router-dom";
import styles from "./stats.module.scss";

type StatsProps = {
  block: Record<string, any>;
  scroll: number;
};

type Stat = {
  image_url: string;
  number: string;
  unit?: string;
  text: string;
};

export default ({ block, scroll }: StatsProps) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (
        ref.current &&
        (ref.current.getBoundingClientRect().top < 200 ||
          ref.current.getBoundingClientRect().bottom < window.innerHeight)
      )
        setShow(true);
    }, 50);
  }, []);

  useEffect(() => {
    if (scroll && ref.current) {
      if (
        ref.current.getBoundingClientRect().top < window.innerHeight * 0.5 &&
        ref.current.getBoundingClientRect().bottom > window.innerHeight * 0.5
      ) {
        setShow(true);
      }
    }
  }, [scroll]);

  const scrollDown = () => {
    window.scrollTo({
      top: window.innerHeight * 0.85 + window.scrollY,
      behavior: "smooth",
    });
  };

  return (
    <section className={styles.stats + ` ${show ? styles.show : ""}`} ref={ref}>
      <div className={styles.top}>
        <h4 className={styles.leftColumn}>{block.attrs.column_one_text}</h4>
        <div className={styles.rightColumn}>
          <p className={styles.rightText}>{block.attrs.column_two_text}</p>
          {block.attrs.cta_text && (
            <div className={styles.ctaContainer}>
              {block.attrs.cta_link && !block.attrs.external && (
                <Link className={styles.cta} to={block.attrs.cta_link}>
                  <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
                  <div className={styles.arrow} />
                </Link>
              )}
              {!block.attrs.cta_link && (
                <button className={styles.cta} onClick={scrollDown}>
                  <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
                  <div className={styles.arrow} />
                </button>
              )}
              {block.attrs.cta_link && block.attrs.external && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.cta}
                  href={block.attrs.cta_link}
                >
                  <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
                  <div className={styles.arrow} />
                </a>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.bottom + ` ${show ? styles.show : ""}`}>
        {block.attrs.stats &&
          block.attrs.stats.map((stat: Stat) => (
            <div className={styles.stat} key={stat.text}>
              <img
                src={stat.image_url}
                className={styles.icon}
                alt={`${stat.text} Icon`}
              />
              <div className={styles.statContent}>
                <div className={styles.number}>
                  {show && (
                    <Countup
                      end={parseInt(stat.number)}
                      className={styles.countup}
                      duration={2}
                    />
                  )}
                  {stat.unit && (
                    <span className={styles.unit}>{stat.unit}</span>
                  )}
                </div>
                <span className={styles.statText} role="Stat Text">
                  {stat.text}
                </span>
              </div>
            </div>
          ))}
      </div>
      <div className={styles.cube} />
    </section>
  );
};
