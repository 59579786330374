import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import styles from './table.module.scss'

type TableProps = {
  block: Record<string, any>
  scroll: number
}

type Column = {
  title: string;
  type: boolean;
  hide_title: boolean
}

type Row = Record<string, any>

export default ({ block, scroll }: TableProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const columns = block.attrs.columns
  const rows = block.attrs.rows
  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)

  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const filteredRows = search ? rows.filter((x: Row) => {
    if (columns) {
      let include = false
      columns.forEach((column: Column) => {
        if (x[column.title].text.toLowerCase().includes(search.toLowerCase())) {
          include = true
        }
      })
      return include
    }
  }) : rows

  return (
    <section className={styles.table + ` ${show ? styles.show : ''}`} ref={ref}>
      <div className={styles.topRow}>
        <h3 className={styles.heading}>{block.attrs.heading}</h3>
        {block.attrs.subheading &&
          <p>{block.attrs.subheading}</p>}
        <input
          className={styles.search}
          placeholder={block.attrs.search_placeholder}
          onChange={e => setSearch(e.target.value)}/>
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.titleRow}>
          {columns && columns.map((column: Column, i: number) => (
            <div key={column.title} className={styles.title}>{!column.hide_title ? column.title : ""}</div>
          ))}
        </div>
        {filteredRows && filteredRows.map((row: Row, rowIndex: number) => (
          <div
            key={rowIndex}
            className={styles.dataRow}
            style={{
              transitionDelay: `.${rowIndex * 2}s`
            }}
          >
            {columns && columns.map((column: Column, columnIndex: number) => (
              <div key={columnIndex} className={styles.rowItem + ` ${column.type ? styles.button : ''}`}>
                {row[column.title].link ? (
                  <Link to={row[column.title].link.replace(window.location.origin, '')}>
                    {row[column.title].text}
                  </Link>
                ): <div>{row[column.title].text}</div>}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={styles.mobile}>
        {filteredRows && filteredRows.map((row: Row, rowIndex: number) => (
          <div
          key={rowIndex}
          className={styles.dataRow}
          style={{
            transitionDelay: `.${rowIndex * 2}s`
          }}
        >
          {columns && columns.map((column: Column, columnIndex: number) => (
            <div key={columnIndex} className={styles.rowItem + ` ${column.type ? styles.button : ""}`}>
              <div className={styles.columnTitle}>{!column.hide_title ? column.title : ""}</div>
              <div className={styles.columnValue}>
                {row[column.title].link ? (
                  <Link to={row[column.title].link.replace(window.location.origin, '')}>
                    {row[column.title].text}
                  </Link>
                ): <div>{row[column.title].text}</div>}
              </div>
            </div>
          ))}
        </div>
        ))}
      </div>
    </section>
  )
}