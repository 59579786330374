import React, { useState } from 'react'
import { Form } from '..'
import { getPath } from '../../../../utilities/getPath'
import BaseForm, { TextField } from "../baseForm"

type JobFormProps = {
  block: Record<string, any>
  setForm: React.Dispatch<Form>
}

export default ({ block, setForm }: JobFormProps) => {
  let initialValues: Record<string, any> = {
    password: ''
  }

  const [formValues, setFormValues] = useState<Record<string, any>>(initialValues);
  const [message, setMessage] = useState<string>("")

  const fields: TextField[] = [
    { label: "New password", name: "password" },
  ]

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setMessage("");
    e.preventDefault();
    window.fetch(`${getPath()}/wp-json/api/v2/change-password`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...formValues,
      })
    }).then(async (res: any) => {
      if (res.status === 200) {
        setMessage("Password updated!")
        setFormValues(initialValues)
      } else {
        const response = await res.json();
        setMessage(response.message)
      }
    })
  }

  const buttons = [
    { label: "Back", callback: () => setForm("Job") }
  ]

  return (
    <BaseForm
      {...{
        message,
        fields,
        handleSubmit, 
        formValues,
        setFormValues,
        setForm,
        buttons,
        submitLabel: "Save"
      }}
    />
  )
}