import React, { useContext } from 'react'

import Layout from '../../layout'
import { Context } from '../../context'

import styles from './single.module.scss'

export default (props: any) => {
  const { posts } = useContext(Context)

  return (
    <Layout>
      <div className={styles.post}>
        <p>this is the page component</p>
        <p>this is the slug: {props.match.params.slug}</p>
      </div>
      {posts && posts.map((post: any, i: number) => (
        <p key={i}>{post}</p>
      ))}
    </Layout>
  )
}
