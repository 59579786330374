import apiFetch from "@wordpress/api-fetch";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getPath } from "../../../utilities/getPath";
import SlantedBorder from "../slantedBorder";
import styles from "./counselors.module.scss";

type CounselorsProps = {
  block: Record<string, any>;
  scroll: number;
};

type Counselor = {
  name: string;
  description: string;
  email: string;
  phone: string;
  link: string;
  image: string;
};

export default ({ block, scroll }: CounselorsProps) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const [counselors, setCounselors] = useState<any>([]);
  const [current, setCurrent] = useState<number | null>(null);

  useEffect(() => {
    apiFetch({
      path: `${getPath()}/wp-json/wp/v2/counselors?per_page=100`,
    }).then((res) => setCounselors(res));

    setTimeout(() => {
      if (ref.current && ref.current.getBoundingClientRect().top < 200)
        setShow(true);
    }, 50);
  }, []);

  const sort = (a: Counselor, b: Counselor) => (a.name < b.name ? -1 : 1);

  return (
    <section className={styles.counselors} ref={ref}>
      <div className={styles.left}>
        {counselors &&
          counselors.sort(sort).map((counselor: Counselor, i: number) => (
            <div
              key={i}
              className={
                styles.counselor + ` ${current === i ? styles.show : ""}`
              }
            >
              <button className={styles.button} onClick={() => setCurrent(i)}>
                {counselor.name}
              </button>
              <div className={styles.details}>
                <p className={styles.description}>{counselor.description}</p>
                <p className={styles.email}>{counselor.email}</p>
                <p className={styles.phone}>{counselor.phone}</p>
                {counselor.link?.startsWith("/") && (
                  <Link className={styles.ctaContainer} to={counselor.link}>
                    <button className={styles.cta}>
                      <p className={styles.buttonLabel}>
                        {block.attrs.cta_text}
                      </p>
                      <div className={styles.arrow} />
                    </button>
                  </Link>
                )}
                {!counselor.link?.startsWith("/") && (
                  <a
                    className={styles.ctaContainer}
                    href={counselor.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Counselor Link"
                  >
                    <button className={styles.cta}>
                      <p className={styles.buttonLabel}>
                        {block.attrs.cta_text}
                      </p>
                      <div className={styles.arrow} />
                    </button>
                  </a>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className={styles.right}>
        {counselors &&
          counselors
            .sort(sort)
            .map((counselor: Counselor, i: number) => (
              <img
                key={i}
                src={counselor.image}
                alt=""
                className={
                  styles.image + ` ${current === i ? styles.show : ""}`
                }
              />
            ))}
        <img
          src={block.attrs.default_image_url}
          className={styles.image + ` ${current === null ? styles.show : ""}`}
          alt=""
        />
      </div>
      {block.attrs.has_slanted_border && block.attrs.slanted_border && (
        <SlantedBorder {...JSON.parse(block.attrs.slanted_border)} />
      )}
    </section>
  );
};
