import React, { useEffect, useState, useRef } from "react"
import { Testimonial } from "."
import parse from "html-react-parser"
import styles from "./testimonials.module.scss"

type TextScrollerProps = {
  direction: 'up' | 'down' | 'unset'
  testimonials: Testimonial[]
  setDirection: React.Dispatch<'up' | 'down' | 'unset'>
  pause: boolean
  onClick: (staff: Testimonial) => void
  setPause: React.Dispatch<boolean>
}

export default (props: TextScrollerProps) => {
  const { direction, testimonials, setDirection, pause } = props
  const [style, setStyle] = useState({})
  const [show, setShow] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const outerWrap = useRef<HTMLDivElement>(null)
  const innerWrap = useRef<HTMLDivElement>(null)

  const reset = (start: string, end: string) => {
    if (outerWrap.current) {
      outerWrap.current.style.transition = 'none'
      outerWrap.current.style.transform = `translateY(${start})`
      setTimeout(() => {
        if (outerWrap.current) {
          outerWrap.current.style.transition = 'all 40s linear'
          outerWrap.current.style.transform = `translateY(${end})`
        }
      }, 50)
    }
  }

  const [timerId, setTimerId] = useState<NodeJS.Timeout>()

  const TextGroup: React.FC = () => (
    <>
      {testimonials && testimonials.map((testimonial: Testimonial, i: number) => (
        <button
          key={i}
          className={styles.testimonial}
          onClick={() => props.onClick(testimonial)}
        >
          {parse(testimonial.name)}
        </button>
      ))}
    </>
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      setStyle({
        transition: 'all 40s linear',
        transform: 'translateY(-50%)'
      })

      setShow(true)
    }, 100)
    if (outerWrap.current && innerWrap.current) {
      const height = innerWrap.current.offsetHeight
      outerWrap.current.scrollTop = height * .5
    }
    setTimerId(setInterval(() => {
      if (outerWrap.current && ref.current) {
        const top = outerWrap.current.getBoundingClientRect().top
        const containerTop = ref.current.getBoundingClientRect().top
        const height = outerWrap.current.offsetHeight;
        const diff = containerTop - top
        const traveled = diff / height * 100
        if (traveled >= 45) {
          reset('0%', '-50%')
        }
      }
    }, 100))

    return (() => { clearTimeout(timer); clearInterval(timerId as NodeJS.Timeout) })
  }, [])

  useEffect(() => {
    if (outerWrap.current && innerWrap.current) {
      const scrollHeight = innerWrap.current.scrollHeight
      let scrollTop = outerWrap.current.scrollTop
      const height = outerWrap.current.clientHeight
      const reset = () => {
        if (outerWrap.current) {
          outerWrap.current.style.scrollBehavior = 'auto'
          outerWrap.current.scrollTop = height * .5
          scrollTop = outerWrap.current.scrollTop
        }
      }
      
      if (direction === 'up') {
        if (scrollTop >= scrollHeight - height) {
          reset()
        }
        outerWrap.current.style.scrollBehavior = 'smooth'
        outerWrap.current.scrollTop = scrollTop + window.innerHeight * .7
        setDirection('unset')
      } else if (direction === 'down') {
        if (scrollTop < 10) {
          reset()
        }
        outerWrap.current.style.scrollBehavior = 'smooth'
        outerWrap.current.scrollTop = scrollTop - window.innerHeight * .7
        setDirection('unset')
      }
    }
  }, [direction])

  useEffect(() => {
    if (pause && outerWrap.current && ref.current) {
      const top = outerWrap.current.getBoundingClientRect().top
      const containerTop = ref.current.getBoundingClientRect().top
      const height = outerWrap.current.offsetHeight;
      const diff = containerTop - top
      const traveled = diff / height * 100
      setStyle({
        transition: 'all 3s ease-out',
        transform: `translateY(-${traveled + 1}%)`
      })
    } else if (pause === false && outerWrap.current && ref.current) {
      const top = outerWrap.current.getBoundingClientRect().top
      const containerTop = ref.current.getBoundingClientRect().top
      const diff = containerTop - top
      const height = outerWrap.current.clientHeight * .5
      setStyle({
        transition: `all ${40 * (1 - (Math.abs(diff) / height))}s linear`,
        transform: `translateY(-50%)`
      })
    }
  }, [pause])

  const volume = 200 / testimonials.length

  const array = new Array(Math.ceil(volume)).fill(0)

  return (
    <div className={styles.testimonialScroller + ` ${show ? styles.show : ''}`} ref={ref}> 
      <div className={styles.container} style={style} ref={outerWrap}>
        <div
          ref={innerWrap}>
          {array.map((_, i: number) => <TextGroup key={i} />)}
        </div>
      </div>
    </div>
  )
}