import React, { useEffect, useRef, useState} from 'react'
import styles from './roomDetails.module.scss'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'

type RoomDetailsProps = {
  block: Record<string, any>
  scroll: number
}

export default ({ block, scroll }: RoomDetailsProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5) &&
      ref.current.getBoundingClientRect().bottom > (window.innerHeight * .25)) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
  }, [scroll])

  const scrollDown = () => {
    window.scrollTo({ top: (window.innerHeight * .85) + window.scrollY, behavior: 'smooth' })
  }

  return (
    <section className={styles.contact + ` ${show ? styles.show : ''}`} ref={ref}>
      <div className={styles.left}>
        <h2 className={styles.heading}>{block.attrs.heading}</h2>
        {block.attrs.cta_text &&
        <div className={styles.ctaContainer}>
          {block.attrs.cta_link?.startsWith("/") &&
          <Link className={styles.cta} to={block.attrs.cta_link}>
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </Link>}
          {!block.attrs.cta_link &&
          <button className={styles.cta} onClick={scrollDown}>
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </button>}
          {!block.attrs.cta_link?.startsWith("/") &&
          <a className={styles.cta} href={block.attrs.cta_link} target="_blank" rel="noreferrer">
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </a>}
        </div>}
      </div>
      <div className={styles.right}>
        <img src={block.attrs.image_url} alt={block.attrs.alt_text} />
      </div>
    </section>
  )
}