import React from 'react'
import { Filters } from '../..'
import styles from './checkboxField.module.scss'

type Props = {
  filters: Filters
  setFilters: React.Dispatch<Filters>
  name: string
}

export default (props: Props) => {
  const { filters, setFilters, name } = props
  const checked = filters.presenting.includes(name)

  const handleChange = () => {
    if (filters.presenting.includes(name)) {
      setFilters({
        ...filters,
        presenting: filters.presenting.filter(x => x !== name)
      })
    
    } else {
      setFilters({
        ...filters,
        presenting: [...filters.presenting, name]
      })
    }
  }

  return (
    <div className={styles.checkboxField}>
      <input
        id={name}
        name={name}
        type='checkbox'
        tabIndex={0}
        checked={checked}
        onChange={handleChange} />
      <label htmlFor={name} tabIndex={0}>
        <span className={styles.box +
          ` ${checked ? styles.active : ''}`}>
        </span>
        <span>{name}</span>
      </label>
    </div>
  )
}