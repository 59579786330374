import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './textImages.module.scss'

type TextImagesProps = {
  block: Record<string, any>
  scroll: number
}

type Image = {
  image_url: ''
}

export default ({ block, scroll }: TextImagesProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const images = block.attrs.images

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const scrollDown = () => {
    window.scrollTo({ top: (window.innerHeight * .85) + window.scrollY, behavior: 'smooth' })
  }

  return (
    <section
      className={styles.textImages + ` ${show ? styles.show : ''}`}
      ref={ref}>
      <div className={styles.left}>
        <h3 className={styles.heading}>{block.attrs.heading}</h3>
        <p className={styles.body}>{block.attrs.body}</p>
        {block.attrs.cta_text &&
        <div className={styles.ctaContainer}>
          {block.attrs.cta_link?.startsWith("/") &&
          <Link className={styles.button} to={block.attrs.cta_link}>
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </Link>}
          {!block.attrs.cta_link &&
          <button className={styles.button} onClick={scrollDown}>
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </button>}
          {block.attrs.cta_link && !block.attrs.cta_link?.startsWith("/") &&
          <a className={styles.button} href={block.attrs.cta_link} target="_blank">
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </a>}
        </div>}
        <div className={styles.bottomOverlay} />
      </div>
      <div className={styles.right}>
        {images && images.map((image: Image, i: number) => (
          <img key={i} src={image.image_url} className={styles[`image${i}`]} alt={`${block.attrs.heading} Image`} />
        ))}
        <div className={styles.topPadding} />
        <div className={styles.topOverlay} />
        <div className={styles.bottomPadding} />
        <div className={styles.bottomOverlay} />
      </div>
    </section>
  )
}