import { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Context } from '../context/context'

type ColorContext = {
  funnels: any[];
}

export function useFunnelColor(path: RegExp) {
  const [color, setColor] = useState<string | null>(null);
  const context = useContext(Context) as ColorContext;
  const setFunnelColor = () => {
    if (context) {
      const funnels = context.funnels;
      const currentPage = window.location.pathname.replace(path, '').replace('programs', '')
      const match = funnels.find(x => {
        if (x && x.programs)
        return (x.programs.find(({ program }: any) => program.post_name === currentPage))
      })
      if (match) {
        setColor(match.color)
      }
    }
  }

  const history = useHistory()

  history.listen(() => {
    setFunnelColor();
  })
  
  useEffect(() => {
    setFunnelColor();
  }, [context, color])

  return color;
}