import React from "react"
import styles from "./slantedBorder.module.scss"

type SlantedBorderProps = {
  color: boolean
  slope: boolean
  position: boolean
}

export default ({ color, slope, position }: SlantedBorderProps) => (
  <div
    className={styles.slantedBorder}
    style={{
      bottom: position ? '-9.5vw' : 'unset',
      top: !position ? '-9.5vw' : 'unset',
      backgroundColor: color ? '#0056B8' : 'white',
      transform: `skew(-3deg, ${!slope ? '-2.5' : '2.5'}deg)`
    }}
  />
)
