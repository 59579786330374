import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './instructor.module.scss'

type InstructorProps = {
  block: Record<string, any>
  scroll: number
}

export default ({ block, scroll }: InstructorProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)


  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight)) {
        setShow(true)
        }
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const scrollDown = () => {
    window.scrollTo({ top: (window.innerHeight * .85) + window.scrollY, behavior: 'smooth' })
  }

  return (
    <section className={styles.instructor + ` ${show ? styles.show : '' }`} ref={ref}>
      <div className={styles.left}>
        <h3
          className={styles.name}>
          Instructor: {block.attrs.name}
        </h3>
        <p
          className={styles.description}
          style={{ transitionDelay: '.2s'}}>
          {block.attrs.description}
        </p>
        <p
          className={styles.email}
          style={{ transitionDelay: '.4s'}}>
          {block.attrs.email}
        </p>
        <p
          className={styles.phone}
          style={{ transitionDelay: '.6s'}}>
          {block.attrs.phone}
        </p>
        {block.attrs.cta_text &&
        <div
          className={styles.ctaContainer}
          style={{ transitionDelay: '.8s'}}>
          {block.attrs.cta_link?.startsWith("/") &&
          <Link className={styles.cta} to={block.attrs.cta_link}>
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </Link>}
          {!block.attrs.cta_link &&
          <button className={styles.cta} onClick={scrollDown}>
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </button>}
          {block.attrs.cta_link && !block.attrs.cta_link?.startsWith("/") &&
          <a className={styles.cta} href={block.attrs.cta_link} target="_blank" rel="noopener noreferrer">
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </a>}
        </div>}
      </div>
      <div className={styles.right}>
        <img src={block.attrs.image_url} alt={block.attrs.image_alt_text} />
      </div>
    </section>
  )
}