import React, { useEffect, useRef, useState } from 'react'
import styles from './map.module.scss'

type CTAContactProps = {
  block: Record<string, any>
  scroll: number
}

export default ({ block, scroll }: CTAContactProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  return (
    <section
      className={styles.map + ` ${show ? styles.show : ''}`}
      ref={ref}>
      <p className={styles.hiddenTitle}>{block.title}</p>
      <img className={styles.image} src={block.attrs.image_url} alt="Map" />
    </section>
  )
}