import parse from "html-react-parser";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./tabsTwoColumns.module.scss";

type TabsTwoColumnsProps = {
  block: Record<string, any>;
  scroll: number;
};

type Tab = {
  label: string;
  content: string;
};

export default ({ block, scroll }: TabsTwoColumnsProps) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const tabs = block.attrs.tabs;
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);
  const [showContent, setShowContent] = useState<boolean>(false);
  const colorScheme = block.attrs.color_scheme;

  useEffect(() => {
    setTimeout(() => {
      if (
        ref.current &&
        (ref.current.getBoundingClientRect().top < 200 ||
          ref.current.getBoundingClientRect().bottom < window.innerHeight)
      )
        setShow(true);
    }, 50);
  }, []);

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < window.innerHeight * 0.5) {
        setShow(true);
      }
    }
  }, [scroll]);

  useEffect(() => {
    setShowContent(false);
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 50);
    return () => clearTimeout(timer);
  }, [activeTab]);

  const scrollDown = () => {
    const sectionElement = ref.current;
    if (sectionElement) {
      const sectionOffset =
        sectionElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: sectionOffset - 160,
        behavior: "smooth",
      });
    }
  };

  return (
    <section
      className={
        styles.tabsTwoColumns +
        ` ${show ? styles.show : ""}` +
        ` ${colorScheme ? styles.light : ""}`
      }
      ref={ref}
    >
      <div className={styles.left}>
        <div className={styles.sticky}>
          {tabs &&
            tabs.map((tab: Tab, i: number) => (
              <button
                key={i}
                onClick={() => {
                  setActiveTab(tab);
                  scrollDown();
                }}
                className={
                  styles.tab +
                  ` ${activeTab.label === tab.label ? styles.active : ""}`
                }
                aria-label={`View ${tab.label}`}
              >
                {tab.label}
              </button>
            ))}
        </div>
      </div>
      <div className={styles.right}>
        <p className={styles.rightText + ` ${!showContent ? styles.hide : ""}`}>
          {parse(activeTab.content)}
        </p>
        {block.attrs.cta_text && (
          <div className={styles.ctaContainer}>
            {block.attrs.cta_link?.startsWith("/") && (
              <Link className={styles.cta} to={block.attrs.cta_link}>
                <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
                <div className={styles.arrow} />
              </Link>
            )}
            {!block.attrs.cta_link && (
              <button className={styles.cta} onClick={scrollDown}>
                <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
                <div className={styles.arrow} />
              </button>
            )}
            {block.attrs.cta_link && !block.attrs.cta_link?.startsWith("/") && (
              <a
                className={styles.cta}
                href={block.attrs.cta_link}
                target="_blank"
                rel="noopener noreferrer"
                role={`${block.attrs.cta_text} Link`}
              >
                <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
                <div className={styles.arrow} />
              </a>
            )}
          </div>
        )}
      </div>
      <div className={styles.cubeOne + ` ${colorScheme ? styles.light : ""}`} />
      <div className={styles.cubeTwo + ` ${colorScheme ? styles.light : ""}`} />
    </section>
  );
};
