import apiFetch from '@wordpress/api-fetch'
import parse from 'html-react-parser'
import React, { createContext, useEffect, useState } from 'react'
import { getPath } from "../utilities/getPath"
// import { useLocation } from 'react-router-dom'

export const Context = createContext<any | null>(null)

export const ContextProvider = ({ children }: any) => {
  const [scroll, setScroll] = useState<number | null>(null)
  const [scrolled, setScrolled] = useState<boolean>(false)
  const [attributes, setAttributes] = useState<any>([])
  const [indexingLoading, setIndexingLoading] = useState<boolean>(false)
  const [funnels, setFunnels] = useState<any>([])
  const [courses, setCourses] = useState<any>([])
  const [shortTermCourses, setShortTermCourses] = useState<any | null>([])
  const [shortTermLoading, setShortTermLoading] = useState<boolean>(true)
  const [flexPrograms, setFlexPrograms] = useState<any | null>([])
  const [safetySkills, setSafetySkills] = useState<any | null>([])
  const [flexLoading, setFlexLoading] = useState<boolean>(true)
  const [safetyLoading, setSafetyLoading] = useState<boolean>(true)
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [resourcesNav, setResourcesNav] = useState<'Adult' | 'HS'>('Adult')

  useEffect(() => {
    apiFetch({ path: `${getPath()}/wp-json/wp/v2/funnels?per_page=100`}).then(res =>  {
      setFunnels(res);
    })
    window.fetch(`${getPath()}/wp-json/api/v2/get-short-term-courses`, { method: 'get'}).then(async res => {
      if (res.status === 200) {
        const response = await res.json()
        setShortTermLoading(false)
        if (response?.Entities) {
          setShortTermCourses(response.Entities)
        }
      }
    })
    window.fetch(`${getPath()}/wp-json/api/v2/get-flex-programs`, { method: 'get'}).then(async res => {
      if (res.status === 200) {
        const response = await res.json()
        setFlexLoading(false)
        if (response?.Entities) {
          setFlexPrograms(response.Entities)
        }
      }
    })
    window.fetch(`${getPath()}/wp-json/api/v2/get-safety-skills`, { method: 'get'}).then(async res => {
      if (res.status === 200) {
        const response = await res.json()
        setSafetyLoading(false)
        if (response?.Entities) {
          setSafetySkills(response.Entities)
        }
      }
    })
    
  }, []);
 
  return (
    <Context.Provider value={{
      scroll,
      setScroll,
      scrolled,
      setScrolled,
      attributes,
      setAttributes,
      indexingLoading,
      setIndexingLoading,
      shortTermCourses,
      shortTermLoading,
      flexPrograms,
      flexLoading,
      safetySkills,
      safetyLoading,
      funnels,
      setFunnels,
      dropdownOpen,
      setDropdownOpen,
      resourcesNav,
      setResourcesNav,
    }}>
      {children}
    </Context.Provider>
  )
}
