import React from 'react'

import Layout from '../../layout'

import styles from './notFound.module.scss'

export default (props: any) => {
  return (
    <Layout>
      <div className={styles.post}>
        <p><code>{props.location.pathname}</code> not found</p>
      </div>
    </Layout>
  )
}
