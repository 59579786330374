import React from "react";
import styles from "./toggleSearch.module.scss";

type SearchToggleProps = {
  searchToggle: "Site" | "Course";
  setSearchToggle: React.Dispatch<"Site" | "Course">;
};

export default ({ searchToggle, setSearchToggle }: SearchToggleProps) => {
  return (
    <div className={styles.toggleField}>
      <label htmlFor={"searchToggle"} tabIndex={0}>
        <span
          className={
            styles.optionOne +
            ` ${searchToggle === "Site" ? styles.active : ""}`
          }
          role="Site Toggle"
        >
          Site
        </span>
        <span
          className={
            styles.toggle + ` ${searchToggle === "Course" ? styles.active : ""}`
          }
        ></span>
        <span
          className={
            styles.optionTwo +
            ` ${searchToggle === "Course" ? styles.active : ""}`
          }
          role="Course Toggle"
        >
          Course
        </span>
      </label>
      <input
        id={"searchToggle"}
        name={"searchToggle"}
        type="checkbox"
        checked={searchToggle === "Course" ? true : false}
        onChange={() =>
          setSearchToggle(searchToggle === "Course" ? "Site" : "Course")
        }
      />
    </div>
  );
};
