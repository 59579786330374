import parse from "html-react-parser"
import moment, { Moment } from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Loader from "react-loader-spinner"
import { Link } from 'react-router-dom'
import { Context } from '../../../context'
import styles from './safetySkills.module.scss'

type TableProps = {
  block: Record<string, any>
  scroll: number
}

export type FlexProgram = {
  Attributes: {
    c9_coursedescription: string
    atc_actualstartdatetime: string
    c9_lastclassdate: string
  },
  FormattedValues: {
    atc_availableenrollments: string
    atc_costoutofdistrict: string
    atc_online_on_demand: string
    atc_sessionformat: string
    c9_category: string
    c9_cluster: string
    c9_course: string
    c9_durationofclassmeetings: string
    c9_faculty: string
    c9_friday: string
    c9_monday: string
    c9_onlinesession: string
    c9_saturday: string
    c9_sunday: string
    c9_thursday: string
    c9_totalcosts: string
    c9_tuesday: string
    c9_wednesday: string
  },
  Id: string
}

type Column = "Course"
  | "Course description"
  | "Course format"
  | "In/out of district cost"

export default ({ block, scroll }: TableProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState<string>('')
  const context = useContext(Context)
  const [current, setCurrent] = useState<number | null>(null);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [context && context.shortTermCourses])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const allCourses = context.safetySkills

  const columns: Column[] = [
    "Course",
    "Course description",
    "Course format",
    "In/out of district cost"
  ];

  const formatCosts = (x: FlexProgram) => {
    const inDistrictCost = x.FormattedValues.c9_totalcosts
    const outOfDistrictCost = x.FormattedValues.atc_costoutofdistrict
    return `${inDistrictCost} / ${outOfDistrictCost}`
  }

  const keyMap = {
    "Course": (x: FlexProgram) => x.FormattedValues.c9_course,
    "Course description": (x: FlexProgram) => x.Attributes.c9_coursedescription,
    "Course format": (x: FlexProgram) => 'On Demand Training',
    "In/out of district cost": (x: FlexProgram) => formatCosts(x)
  }

  const trimText = (x: string, i: number) => {
    if (current === i) {
      return `<p>${x}</p>`
    }
    const text = x.length > 75
      ? `<p>${x.slice(0, 75)} . . .<strong>expand</strong></p>`
      : `<p>${x}</p>`
    return text
  }

  const trimMobileText = (x: string, i: number) => {
    if (current === i) {
      return `<p>${x}</p>`
    }
    const text = x.length > 60
      ? `<p>${x.slice(0, 60)} . . .<strong>expand</strong></p>`
      : `<p>${x}</p>`
    return text
  }

  const filteredCourses = search ? allCourses.filter((course: FlexProgram) => {
    if (columns) {
      let include = false
      columns.forEach((column: Column) => {
        if (keyMap[column](course).toLowerCase().includes(search.toLowerCase())) {
          include = true
        }
      })
      return include
    }
  }) : allCourses

  if (context && context.safetyLoading) {
    return (
      <section className={styles.table + ` ${show ? styles.show : ''}`} ref={ref}>
        <div className={styles.topRow}>
          <h3 className={styles.heading}>Safety Skills Courses</h3>
        </div>
        <Loader type="TailSpin" color="#091C3D" height={50} width={50} />
      </section>
    )
  }

  return (
    <section className={styles.table + ` ${show ? styles.show : ''}`} ref={ref}>
      <div className={styles.topRow}>
        <h3 className={styles.heading}>Safety Skills Courses</h3>
        <input
          className={styles.search}
          placeholder={block.attrs.search_placeholder}
          onChange={e => setSearch(e.target.value)}/>
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.titleRow}>
          {columns.map((column, i: number) => (
            <div key={column} className={styles.title
               + ` ${i === 1 ? styles.wide : ""}`
            }>{column}</div>
          ))}
          <div className={styles.title} />
        </div>
        {filteredCourses && filteredCourses.map((course: FlexProgram, courseIndex: number) => (
          <div
            key={courseIndex}
            className={styles.dataRow}
            style={{
              transitionDelay: `.${courseIndex * 2}s`
            }}
          >
            {columns &&
              <a
                href={`https://www.autryportal.com/session/session-details/?id=${course.Id}`}
                target="_blank"
                rel="noopener noreferrer"
                className={
                  styles.rowItem + ` ${current === courseIndex ? styles.current : ""}`
                  + ` ${styles.first}`
                }>
                <div className={current === courseIndex ? styles.current : ""}>
                  {keyMap.Course(course)}
                </div>
              </a>
            }
            {columns && columns.slice(1).map((column, columnIndex: number) => (
              <button
                onClick={() => setCurrent(courseIndex === current ? null : courseIndex)}
                key={columnIndex}
                className={
                  styles.rowItem + ` ${columnIndex === 0 ? styles.wide : ""}`
                   + ` ${current === courseIndex ? styles.current : ""}`
                }>
                <div className={current === courseIndex ? styles.current : ""}>
                  {keyMap[column](course).length > 75
                    ? parse(trimText(keyMap[column](course), courseIndex))
                    : keyMap[column](course)}
                </div>
              </button>
            ))}
            <a
              href={`https://www.autryportal.com/session/session-details/?id=${course.Id}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.apply}>
              <div>
                Apply now
              </div>
            </a>
          </div>
        ))}
      </div>
      <div className={styles.mobile}>
        {filteredCourses && filteredCourses.map((course: FlexProgram, rowIndex: number) => (
          <div
            key={rowIndex}
            className={styles.dataRow}
            style={{
              transitionDelay: `.${rowIndex * 2}s`
            }}
          >
            {columns &&
              <a
                href={`https://www.autryportal.com/session/session-details/?id=${course.Id}`}
                target="_blank"
                rel="noopener noreferrer"
                className={
                  styles.rowItem + ` ${current === rowIndex ? styles.current : ""}`
                  + ` ${styles.first}`
                }>
                <div className={current === rowIndex ? styles.current : ""}>
                  {keyMap.Course(course)}
                </div>
              </a>
            }
            {columns && columns.slice(1).map((column, columnIndex: number) => (
              <button
                onClick={() => setCurrent(rowIndex === current ? null : rowIndex)}
                key={columnIndex}
                className={
                  styles.rowItem + ` ${columnIndex === 1 ? styles.wide : ""}`
                   + ` ${current === rowIndex ? styles.current : ""}`
                }>
                <div className={styles.columnTitle}>{column}</div>
                <div className={styles.columnValue}>
                  <div>
                    {keyMap[column](course).length > 60
                      ? parse(trimMobileText(keyMap[column](course), rowIndex))
                      : keyMap[column](course)}
                  </div>
                </div>
              </button>
            ))}
            <a
              href={`https://www.autryportal.com/session/session-details/?id=${course.Id}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.apply}>
              <div>
                Apply now
              </div>
            </a>
          </div>
        ))}
      </div>
    </section>
  )
}