import apiFetch from "@wordpress/api-fetch";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getPath } from "../../../utilities/getPath";
import styles from "./conversation.module.scss";

type GridProps = {
  block: Record<string, any>;
  scroll: number;
};

type Item = {
  text: string;
  image: string;
  link: string;
};

export default ({ block, scroll }: GridProps) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const [post, setPost] = useState<any>();

  useEffect(() => {
    setTimeout(() => {
      if (
        ref.current &&
        (ref.current.getBoundingClientRect().top < 200 ||
          ref.current.getBoundingClientRect().bottom < window.innerHeight)
      )
        setShow(true);
    }, 50);
    apiFetch({
      path: `${getPath()}/wp-json/wp/v2/conversation?per_page=1`,
    }).then((res: any) => setPost(res[0]));
  }, []);

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < window.innerHeight * 0.5) {
        setShow(true);
      }
    }
  }, [scroll]);

  if (!post) return null;

  const items = [
    { text: post.web_text, link: post.web_link, image: post.web_icon },
    { text: post.map_text, link: post.map_link, image: post.map_icon },
    { text: post.phone_text, link: post.phone_number, image: post.phone_icon },
  ];

  return (
    <section
      className={styles.picker + ` ${show ? styles.show : ""}`}
      ref={ref}
    >
      <div className={styles.background} />
      <h4 className={styles.heading}>{post.heading}</h4>
      <p className={styles.subheading}>{post.body}</p>
      <div className={styles.items}>
        {items &&
          items.map((item: Item, i: number) => (
            <div className={styles.item} key={i}>
              {!item.link?.startsWith("/") && (
                <a
                  key={i}
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    transitionDelay: `${0.2 + i * 0.2}s`,
                  }}
                  aria-label={`${item.text} Link`}
                >
                  <img src={item.image} alt={`${item.text} Icon`} />
                  <p>{item.text}</p>
                </a>
              )}
              {item.link?.startsWith("/") && (
                <Link
                  key={i}
                  to={item.link}
                  style={{
                    transitionDelay: `${0.2 + i * 0.2}s`,
                  }}
                >
                  <img src={item.image} alt={`${item.text} Icon`} />
                  <p>{item.text}</p>
                </Link>
              )}
            </div>
          ))}
      </div>
    </section>
  );
};
