import React from 'react'
import styles from './checkboxListField.module.scss'
import { Option } from "../../"

type CheckboxListFieldProps = {
  setValues: React.Dispatch<any>
  values: any
  name: string
  label: string
  options?: Option[]
}

export default ({ name, label, values, options, setValues }: CheckboxListFieldProps) => {
  const updateValues = (checked: boolean, value: string) => {
    if (checked) {
      setValues({
        ...values,
        [name]: [...values[name], value]
      })
    } else {
      setValues({
        ...values,
        [name]: values[name].filter((x: string) => x !== value)
      })
    }
  }

  return (
    <div className={styles.checkboxListField}>
      <fieldset>
        <legend>
          {label}
        </legend>
        {options && options.map((option: Option) => (
          <div className={styles.checkboxField} key={option.value}>
            <input
              id={option.value}
              name={option.value}
              type='checkbox'
              tabIndex={0}
              checked={values[name].includes(option.value)}
              onChange={e => updateValues(e.target.checked, option.value) } />
            <label htmlFor={option.value} tabIndex={0}>
              <span className={styles.box +
                ` ${values[name].includes(option.value) ? styles.active : ''}`}>
              </span>
              <span>{option.label}</span>
            </label>
          </div>
        ))}
      </fieldset>
    </div>
  )
}