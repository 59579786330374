import React, { useEffect, useRef, useState } from "react"
import styles from './grid.module.scss'

type GridProps = {
  block: Record<string, any>
  scroll: number
}

type Item = {
  name: string
  image_url: string
}

export default ({ block, scroll }: GridProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [scrollCount, setScrollCount] = useState<number>(0)
  const [remainder, setRemainder] = useState<number>(0)

  const items = block.attrs.items
  const colorScheme = block.attrs.color_scheme

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)

    const resize = () => {
      setScrollCount(0)
      calculateRemainder()
      if (containerRef.current) {
        containerRef.current.scrollLeft = 0
      }
    }

    const calculateRemainder = () => {
      const length = items?.length ?? 0
      const desktopRemain = 4 - (length % 4 != 0 ? (length % 4) : 4)
      const mobileRemain = 2 - (length % 2 != 0 ? (length % 2) : 2)
       if (length > 0) {
        setRemainder(window.innerWidth > 500 ? desktopRemain : mobileRemain)
      } else { setRemainder(0) }
    }
   
    calculateRemainder()

    window.addEventListener('resize', resize)
    return (() => {
      window.removeEventListener('resize', resize)
    })
  }, [items])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const scrollLeft = () => {
    if (containerRef.current) {
      const container = containerRef.current
      const width = container.offsetWidth
      const count = window.innerWidth > 500
        ? Math.ceil(items.length / 4)
        : Math.ceil(items.length / 2)
      if (scrollCount < count) {
        container.scrollLeft = container.scrollLeft + width
        setScrollCount(scrollCount + 1)
      } else {
        container.style.scrollBehavior = 'auto'
        container.scrollLeft = 0
        container.style.scrollBehavior = 'smooth'
        container.scrollLeft = container.scrollLeft + width
        setScrollCount(1)
      }
    }
  }
  
  const scrollRight = () => {
    if (containerRef.current) {
      const container = containerRef.current
      const width = container.offsetWidth
      const count = window.innerWidth > 500
        ? Math.ceil(items.length / 4)
        : Math.ceil(items.length / 2)
      if (scrollCount > 0) {
        container.scrollLeft = container.scrollLeft - width
        setScrollCount(scrollCount - 1)
      } else {
        container.style.scrollBehavior = 'auto'
        new Array(count).fill(0).forEach(x => {
          container.scrollLeft = container.scrollLeft + width
        })
        container.style.scrollBehavior = 'smooth'
        container.scrollLeft = container.scrollLeft - width
        setScrollCount(count - 1)
      }
    }
  }

  return (
    <section className={styles.grid + ` ${show ? styles.show : ''}` +
    ` ${colorScheme ? styles.light : ''}`} ref={ref}>
      <h4 className={styles.heading}>{block.attrs.heading}</h4>
      <button className={styles.arrowLeft} onClick={scrollRight} aria-label="Click to scroll to the left" />
      <div className={styles.items} ref={containerRef}>
        {items && items.map((item: Item, i: number) => (
          <div
            key={i}
            className={styles.item}
            style={{
              transitionDelay: `${.2 + i * .2}s`
            }}
            >
              {item.image_url && (

            <img src={item.image_url} alt={item.name} />
              )}
            <p>{item.name}</p>
          </div>
        ))}
        {remainder > 0 && new Array(remainder).fill(0).map((x: any, i: number) => (
        <div key={i} className={styles.item} />
        ))}
        {items && items.map((item: Item, i: number) => (
          <div
            key={i}
            className={styles.item}
            style={{
              transitionDelay: `${.2 + i * .2}s`
            }}
            >
            <img src={item.image_url} alt={item.name} />
            <p>{item.name}</p>
          </div>
        ))}
        {remainder > 0 && new Array(remainder).fill(0).map((x: any, i: number) => (
        <div key={i} className={styles.item} />
        ))}
      
      </div>
      <button className={styles.arrowRight} onClick={scrollLeft} aria-label="Click to scroll right"/>
    </section>
  )
}