import React, { useEffect, useRef, useState } from 'react'
import { Link, Link as RouterLink } from 'react-router-dom'
import SlantedBorder from "../slantedBorder"
import styles from './textMultipleLinks.module.scss'

type TextMultipleLinksProps = {
  block: Record<string, any>
  scroll: number
}

type PageLink = {
  text: string
  link: string
  image_alt_text: string
  image_url: string
  external: boolean
}

export default ({ block, scroll }: TextMultipleLinksProps) => {
  const links = block.attrs.links
  
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const scrollDown = () => {
    window.scrollTo({ top: (window.innerHeight * .85) + window.scrollY, behavior: 'smooth' })
  }

  return (
    <section className={styles.textMultipleLinks + ` ${show ? styles.show : ''}`} ref={ref}>
      <h4 className={styles.heading}>{block.attrs.heading}</h4>
      <h6 className={styles.subheading}>{block.attrs.subheading}</h6>
      <div className={styles.links}>
        {links && links.map((link: PageLink, i: number) => (
          <div className={styles.linkWrapper} key={link.text}>
            {link.link && !link.external &&
              <RouterLink
                to={link.link}
                className={styles.link}
                style={{
                  transitionDelay: `.${i + 2}s`
                }}>
                <img src={link.image_url} alt={link.text} />
                <span>{link.text}</span>
              </RouterLink>}
            {link.link && link.external &&
              <a
                href={link.link}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
                style={{
                  transitionDelay: `.${i + 2}s`
                }}>
                <img src={link.image_url} alt={link.text} />
                <span>{link.text}</span>
              </a>
            }
            {!link.link &&
              <div className={styles.link} style={{
                transitionDelay: `.${i + 2}s`
              }}>
                <img src={link.image_url} alt={link.text} />
                <span>{link.text}</span>
              </div>}
          </div>
          
        ))}
      </div>
      {block.attrs.cta_text &&
      <div className={styles.ctaContainer}>
        {block.attrs.cta_link &&
        <Link className={styles.cta} to={block.attrs.cta_link}>
          <p>{block.attrs.cta_text}</p>
          <div className={styles.arrowContainer}>
            <div className={styles.arrow} />
          </div>
        </Link>}
        {!block.attrs.cta_link &&
          <button className={styles.cta} onClick={scrollDown}>
            <p>{block.attrs.cta_text}</p>
            <div className={styles.arrowContainer}>
              <div className={styles.arrow} />
            </div>
          </button>}
      </div>}
      {block.attrs.has_slanted_border && block.attrs.slanted_border &&
      <SlantedBorder {...JSON.parse(block.attrs.slanted_border)} />}
    </section>
  )
}