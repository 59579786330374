import React, { useEffect, useState, useRef, useContext } from 'react'
import styles from './flexPrograms.module.scss'
import { Context } from '../../../context'
import { Link } from 'react-router-dom'

type TableProps = {
  block: Record<string, any>
  scroll: number
}

export default ({ block, scroll }: TableProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const context = useContext(Context)

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [context && context.shortTermCourses])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  return (
    <section className={styles.table + ` ${show ? styles.show : ''}`} ref={ref}>
      <div className={styles.topRow}>
        <h3 className={styles.heading}>Flex programs</h3>
      </div>
      <p className={styles.sorry}>We’re sorry! We don’t have any programs in this area scheduled at this time. <Link to="/contact">Contact</Link> us to discuss personalized training options to meet your needs!</p>
    </section>
  )
}