import React, { useEffect, useState } from 'react'
import apiFetch from '@wordpress/api-fetch'
import parse from 'html-react-parser'

import Layout from '../layout'
import { getPath } from '../utilities/getPath'

export default (props: any) => {
  const [page, setPage] = useState({
    content: { rendered: '' },
    title: { rendered: '' }
  })
  
  useEffect(() => {
    const paths = {
      programs: `${getPath()}/wp-json/wp/v2/programs?slug=${props.location.pathname.replace(/\/programs\/|\//g, '')}`,
      rooms: `${getPath()}/wp-json/wp/v2/rooms?slug=${props.location.pathname.replace(/\/rooms\/|\//g, '')}`,
      pages: `${getPath()}/wp-json/wp/v2/pages?slug=${props.match.params.slug || 'home'}`,
      funnels: `${getPath()}/wp-json/wp/v2/funnels?slug=${props.location.pathname.replace(/\/funnels\/|\//g, '')}`,
    }

    const fetchPage = (path: string) => {
      apiFetch({ path })
      .then((res: any) => { console.log(res); setPage(res[0]) })
    }

    const slug = props.match.params.slug

    if (slug === 'programs') {
      fetchPage(paths.programs)
    
    } else if (slug === 'rooms') {
      fetchPage(paths.rooms)
    
    } else if (slug === 'funnels') {
      fetchPage(paths.funnels)
    
    } else {
      fetchPage(paths.pages)
    }
   
  }, [props])

  return (
    <Layout title={parse(page?.title.rendered ?? "")}>
      {page || (
        <div>
          <p>this is not a page</p>
          <p>this is the slug: {props.match.params.slug}</p>
        </div>
      )}
    </Layout>
  )
}
