import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./ctaContact.module.scss";

type CTAContactProps = {
  block: Record<string, any>;
  scroll: number;
};

type TextField = {
  label: string;
  field_name: string;
};

export default ({ block, scroll }: CTAContactProps) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const textFields = block.attrs.text_fields;

  let initialValues: Record<string, any> = { message: "" };

  textFields.forEach((field: TextField) => {
    if (field.field_name) {
      initialValues[field.field_name] = "";
    }
  });

  const [formValues, setFormValues] =
    useState<Record<string, any>>(initialValues);

  useEffect(() => {
    setTimeout(() => {
      if (
        ref.current &&
        (ref.current.getBoundingClientRect().top < 200 ||
          ref.current.getBoundingClientRect().bottom < window.innerHeight)
      )
        setShow(true);
    }, 50);
  }, []);

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < window.innerHeight * 0.5) {
        setShow(true);
      }
    }
  }, [scroll]);

  const scrollDown = () => {
    window.scrollTo({
      top: window.innerHeight * 0.85 + window.scrollY,
      behavior: "smooth",
    });
  };
  return (
    <section
      className={styles.ctaContact + ` ${show ? styles.show : ""}`}
      ref={ref}
    >
      <h3 className={styles.heading}>{block.attrs.heading}</h3>
      <form>
        {textFields &&
          textFields.map((field: TextField, i: number) => (
            <div className={styles.textField} key={field.field_name}>
              <input
                key={field.field_name}
                name={field.field_name}
                value={formValues[field.field_name]}
                className={styles.textField}
                style={{ transitionDelay: `.${i * 2}s` }}
                placeholder={field.label}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [field.field_name]: e.target.value,
                  })
                }
              />
            </div>
          ))}
        <div className={styles.message}>
          <textarea
            name="message"
            value={formValues.message}
            style={{ transitionDelay: `${textFields.length * 0.2}s` }}
            className={styles.message}
            placeholder={block.attrs.message_field_label}
            onChange={(e) =>
              setFormValues({ ...formValues, message: e.target.value })
            }
          />
        </div>
        {block.attrs.cta_text && (
          <div
            className={styles.ctaContainer}
            style={{ transitionDelay: `${textFields.length * 0.2 + 0.4}s` }}
          >
            {block.attrs.cta_link?.startsWith("/") && (
              <Link className={styles.cta} to={block.attrs.cta_link}>
                <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
                <div className={styles.arrow} />
              </Link>
            )}
            {!block.attrs.cta_link && (
              <button className={styles.cta} onClick={scrollDown}>
                <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
                <div className={styles.arrow} />
              </button>
            )}
            {block.attrs.cta_link && !block.attrs.cta_link?.startsWith("/") && (
              <a
                className={styles.cta}
                href={block.attrs.cta_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
                <div className={styles.arrow} />
              </a>
            )}
          </div>
        )}
      </form>
    </section>
  );
};
