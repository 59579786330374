import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import styles from './programDetails.module.scss'
import { useFunnelColor } from '../../../utilities/useFunnelColor'

type ProgramProps = {
  block: Record<string, any>
  scroll: number
}

type LineItem = {
  text: string
  image_url: string
  link: string
}

export default ({ block, scroll }: ProgramProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const color = useFunnelColor(/\/funnels\/|\//g)

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const scrollDown = () => {
    window.scrollTo({ top: (window.innerHeight * .85) + window.scrollY, behavior: 'smooth' })
  }

  return (
    <section
      className={styles.programDetails +
      ` ${show ? styles.show : ''}`}
      ref={ref}>
      <div className={styles.container} style={{ borderTop: `10px solid ${color}`}}>
        <div className={styles.content} ref={contentRef}>
          <div className={styles.left}>
            <img src={block.attrs.image_url}></img>
          </div>
          <div className={styles.right}>
            <h3 className={styles.heading}>{block.attrs.heading}</h3>
            <p className={styles.description}>{block.attrs.description}</p>
            <ul>
              {block.attrs.line_items && block.attrs.line_items.map((lineItem: LineItem, i: number) => (
                <li
                  key={i}
                  className={styles.lineItem}
                  style={{
                    transitionDelay: `${.4 + i * .2}s`
                  }}
                  >
                  {lineItem.link?.startsWith("/") &&
                  <Link
                    to={lineItem.link}>
                    <img src={lineItem.image_url} />
                    <span>{lineItem.text}</span>
                  </Link>}
                  {!lineItem.link?.startsWith("/") &&
                  <a
                    href={lineItem.link}
                    target="_blank"
                    rel="noreferrer">
                    <img src={lineItem.image_url} />
                    <span>{lineItem.text}</span>
                  </a>}
                </li>
              ))}
            </ul>
            <h6 className={styles.salaryHeading}>{block.attrs.salary_heading}</h6>
            <p className={styles.salarySource}>{block.attrs.salary_source}</p>
            <p
              style={{ color: color ?? '#091C3D'}}
              className={styles.salary}>
              {`$${block.attrs.salary_min} - ${block.attrs.salary_max}/hr`}
            </p>
            {block.attrs.cta_text &&
            <div className={styles.ctaContainer}>
              {block.attrs.cta_link?.startsWith("/") &&
              <Link className={styles.cta} to={block.attrs.cta_link}>
                <p>{block.attrs.cta_text}</p>
                <div className={styles.arrowContainer}>
                  <div className={styles.arrow} />
                </div>
              </Link>}
              {!block.attrs.cta_link &&
              <button className={styles.cta} onClick={scrollDown}>
                <p>{block.attrs.cta_text}</p>
                <div className={styles.arrowContainer}>
                  <div className={styles.arrow} />
                </div>
              </button>}
              {block.attrs.cta_link && !block.attrs.cta_link?.startsWith("/") &&
              <a className={styles.cta} href={block.attrs.cta_link} target="_blank" rel="noreferrer">
                <p>{block.attrs.cta_text}</p>
                <div className={styles.arrowContainer}>
                  <div className={styles.arrow} />
                </div>
              </a>}
            </div>}
          </div>
        </div>
      </div>
    </section>
  )
}