import React from "react"
import styles from './video.module.scss'
import SlantedBorder from "../slantedBorder"

type Props = {
  block: Record<string, any>
  scroll: number
}

export default ({ block, scroll }: Props) => {
  let videoUrl = block.attrs.video_url;

  if (videoUrl.includes('youtube')) {
    const id = videoUrl.split('embed/')[1].split('?')[0];
    videoUrl += `?playlist=${id}&loop=1&rel=0`;
  }

  return (
    <section className={styles.video}>
      <div className={styles.videoContainer}>
        <div className={styles.videoWrapper}>
          <iframe
            width="560"
            height="349"
            src={videoUrl}
            allowFullScreen>
          </iframe>
        </div>
      </div>
    </section>
  )
}