import parse from "html-react-parser"
import moment, { Moment } from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Loader from "react-loader-spinner"
import { Link } from 'react-router-dom'
import { Context } from '../../../context'
import styles from './shortTermCoursesAll.module.scss'

type TableProps = {
  block: Record<string, any>
  scroll: number
}

export type ShortTermCourse = {
  Attributes: {
    c9_coursedescription: string
    atc_actualstartdatetime: string
    c9_lastclassdate: string
  },
  FormattedValues: {
    atc_availableenrollments: string
    atc_costoutofdistrict: string
    atc_online_on_demand: string
    atc_sessionformat: string
    c9_category: string
    c9_cluster: string
    c9_course: string
    c9_durationofclassmeetings: string
    c9_faculty: string
    c9_friday: string
    c9_monday: string
    c9_onlinesession: string
    c9_saturday: string
    c9_sunday: string
    c9_thursday: string
    c9_totalcosts: string
    c9_tuesday: string
    c9_wednesday: string
  },
  Id: string
}

type Column = "Course"
  | "Course description"
  | "Course format"
  | "Course dates"
  | "Days & times"
  | "In/out of district cost"

export default ({ block, scroll }: TableProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState<string>('')
  const context = useContext(Context)
  const [current, setCurrent] = useState<number | null>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const categories = [
    "Agriculture, Food, and Natural Resources",
    "Architecture and Construction",
    "Arts and Communications",
    "Business Management and Leadership",
    "Health and Emergency Services",
    "Hospitality and Human Services",
    "Information Technology",
    "Manufacturing and Trades",
    "Retail",
    "Transportation",
  ]

  const [category, setCategory] = useState<string>(categories[0])

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [context && context.shortTermCourses])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const allCourses = context?.shortTermCourses ?? [];

  const categorizedCourses = allCourses?.filter((x: ShortTermCourse) => {
    return category.includes(x.FormattedValues.c9_cluster)
  })

  const columns: Column[] = [
    "Course",
    "Course description",
    "Course format",
    "Course dates",
    "Days & times",
    "In/out of district cost"
  ];

  const formatDate = (x: string) => moment(x).format("M/D/YYYY")

  const start = (x: ShortTermCourse) => formatDate(x.Attributes.atc_actualstartdatetime)

  const end = (x: ShortTermCourse) => formatDate(x.Attributes.c9_lastclassdate)

  const formatTime = (x: string | Moment) => moment(x).format("h:mma").replace(/\:00/, "")

  const formatEndTime = (x: ShortTermCourse) => {
    const duration = +x.FormattedValues.c9_durationofclassmeetings
    const startDate = moment(x.Attributes.atc_actualstartdatetime)
    const end = startDate.add(duration, 'hours')
    return formatTime(end)
  }

  const formatDays = (x: ShortTermCourse) => {
    const days: string[] = [];
    if (x.FormattedValues.c9_sunday === "Yes") {
      days.push("SU")
    }
    if (x.FormattedValues.c9_monday === "Yes") {
      days.push("M")
    }
    if (x.FormattedValues.c9_tuesday === "Yes") {
      days.push("T")
    }
    if (x.FormattedValues.c9_wednesday === "Yes") {
      days.push("W")
    }
    if (x.FormattedValues.c9_thursday === "Yes") {
      days.push("TH")
    }
    if (x.FormattedValues.c9_friday === "Yes") {
      days.push("F")
    }
    if (x.FormattedValues.c9_saturday === "Yes") {
      days.push("SA")
    }
    return days.join("")
  }

  const formatDaysTimes = (x: ShortTermCourse) => {
    const startDate = x.Attributes.atc_actualstartdatetime
    const days = formatDays(x)
    const start = formatTime(startDate)
    const end = formatEndTime(x)
    return `${days} ${start}-${end}`
  }

  const formatCosts = (x: ShortTermCourse) => {
    const inDistrictCost = x.FormattedValues.c9_totalcosts
    const outOfDistrictCost = x.FormattedValues.atc_costoutofdistrict
    if (parseInt(inDistrictCost.replace('$','')) === 0 &&
        parseInt(outOfDistrictCost.replace('$','')) === 0) {
      return 'see description'
    }
    return `${inDistrictCost} / ${outOfDistrictCost}`
  }

  const keyMap = {
    "Course": (x: ShortTermCourse) => x.FormattedValues.c9_course,
    "Course description": (x: ShortTermCourse) => x.Attributes.c9_coursedescription,
    "Course format": (x: ShortTermCourse) => x.FormattedValues.atc_sessionformat,
    "Course dates": (x: ShortTermCourse) => `${start(x)} - ${end(x)}`,
    "Days & times": (x: ShortTermCourse) => formatDaysTimes(x),
    "In/out of district cost": (x: ShortTermCourse) => formatCosts(x)
  }

  const trimText = (x: string, i: number) => {
    if (current === i) {
      return `<p>${x.replace(/\^.*/, "")}</p>`
    }
    const text = x.length > 75
      ? `<p>${x.slice(0, 75).replace(/\^.*/, "")} . . .<strong>expand</strong></p>`
      : `<p>${x.replace(/\^.*/, "")}</p>`
    return text
  }

  const trimMobileText = (x: string, i: number) => {
    if (current === i) {
      return `<p>${x.replace(/\^.*/, "")}</p>`
    }
    const text = x.length > 60
      ? `<p>${x.slice(0, 60).replace(/\^.*/, "")} . . .<strong>expand</strong></p>`
      : `<p>${x.replace(/\^.*/, "")}</p>`
    return text
  }

  const filteredCourses = search ? categorizedCourses.filter((course: ShortTermCourse) => {
    if (columns) {
      let include = false
      columns.forEach((column: Column) => {
        if (keyMap[column](course).toLowerCase().includes(search.toLowerCase())) {
          include = true
        }
      })
      return include
    }
  }) : categorizedCourses

  if (context && context.shortTermLoading) {
    return (
      <section className={styles.table + ` ${show ? styles.show : ''}`} ref={ref}>
        <div className={styles.topRow}>
          <h3 className={styles.heading}>Short term courses</h3>
        </div>
        <Loader type="TailSpin" color="#091C3D" height={50} width={50} />
      </section>
    )
  }

  if (!categorizedCourses || categorizedCourses.length === 0) {
    return (
      <section className={styles.table + ` ${show ? styles.show : ''}`} ref={ref}>
        <div className={styles.topRow}>
          <h3 className={styles.heading}>Short term courses</h3>
        </div>
        <p className={styles.sorry}>We’re sorry! We don’t have any courses in this area scheduled at this time. <Link to="/contact">Contact</Link> us to discuss personalized training options to meet your needs!</p>
      </section>
    )
  }

  return (
    <section className={styles.table + ` ${show ? styles.show : ''}`} ref={ref}>
      <div className={styles.topRow}>
        <h3 className={styles.heading}>Short term courses</h3>
        <button
          className={styles.menu + ` ${menuOpen ? styles.open : ''}`}
        >
          <div className={styles.chevron} onClick={() => setMenuOpen(!menuOpen)}/>
          <div className={styles.options}>
            <button
              className={styles.openButton}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {category}
            </button>
            {categories
              .filter(c => c !== category)
              .map((c, i) => (
              <button
                key={i}
                className={styles.optionButton}
                onClick={() => { setCategory(c); setMenuOpen(false) }}
              >
                {c}
              </button>
            ))}
          </div>
        </button>
        <input
          className={styles.search}
          placeholder={block.attrs.search_placeholder}
          onChange={e => setSearch(e.target.value)}/>
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.titleRow}>
          {columns.map((column, i: number) => (
            <div key={column} className={styles.title
               + ` ${i === 1 ? styles.wide : ""}`
            }>{column}</div>
          ))}
          <div className={styles.title} />
        </div>
        {filteredCourses && filteredCourses.map((course: ShortTermCourse, courseIndex: number) => (
          <div
            key={courseIndex}
            className={styles.dataRow}
            style={{
              transitionDelay: `.${courseIndex * 2}s`
            }}
          >
            {columns &&
              <a
                href={`https://www.autryportal.com/session/session-details/?id=${course.Id}`}
                target="_blank"
                rel="noopener noreferrer"
                className={
                  styles.rowItem + ` ${current === courseIndex ? styles.current : ""}`
                  + ` ${styles.first}`
                }>
                <div className={current === courseIndex ? styles.current : ""}>
                  {keyMap.Course(course)}
                </div>
              </a>
            }
            {columns && columns.slice(1).map((column, columnIndex: number) => (
              <button
                onClick={() => setCurrent(courseIndex === current ? null : courseIndex)}
                key={columnIndex}
                className={
                  styles.rowItem + ` ${columnIndex === 0 ? styles.wide : ""}`
                   + ` ${current === courseIndex ? styles.current : ""}`
                }>
                <div className={current === courseIndex ? styles.current : ""}>
                  {keyMap[column](course).length > 75
                    ? parse(trimText(keyMap[column](course), courseIndex))
                    : keyMap[column](course)}
                </div>
              </button>
            ))}
            <a
              href={`https://www.autryportal.com/session/session-details/?id=${course.Id}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.apply}>
              <div>
                Enroll now
              </div>
            </a>
          </div>
        ))}
      </div>
      <div className={styles.mobile}>
        {filteredCourses && filteredCourses.map((course: ShortTermCourse, rowIndex: number) => (
          <div
            key={rowIndex}
            className={styles.dataRow}
            style={{
              transitionDelay: `.${rowIndex * 2}s`
            }}
          >
            {columns &&
              <a
                href={`https://www.autryportal.com/session/session-details/?id=${course.Id}`}
                target="_blank"
                rel="noopener noreferrer"
                className={
                  styles.rowItem + ` ${current === rowIndex ? styles.current : ""}`
                  + ` ${styles.first}`
                }>
                <div className={current === rowIndex ? styles.current : ""}>
                  {keyMap.Course(course)}
                </div>
              </a>
            }
            {columns && columns.slice(1).map((column, columnIndex: number) => (
              <button
                onClick={() => setCurrent(rowIndex === current ? null : rowIndex)}
                key={columnIndex}
                className={
                  styles.rowItem + ` ${columnIndex === 1 ? styles.wide : ""}`
                   + ` ${current === rowIndex ? styles.current : ""}`
                }>
                <div className={styles.columnTitle}>{column}</div>
                <div className={styles.columnValue}>
                  <div>
                    {keyMap[column](course).length > 60
                      ? parse(trimMobileText(keyMap[column](course), rowIndex))
                      : keyMap[column](course)}
                  </div>
                </div>
              </button>
            ))}
            <a
              href={`https://www.autryportal.com/session/session-details/?id=${course.Id}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.apply}>
              <div>
                Enroll now
              </div>
            </a>
          </div>
        ))}
      </div>
    </section>
  )
}