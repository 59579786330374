import React from 'react'
import { Link } from 'react-router-dom'

const styles = require('./button.module.scss')

type props = {
  button: { label: string; link: string }
  setFormShow: React.Dispatch<boolean>
}

export default ({ button, setFormShow }: props) => {
  return (
    <div className={styles.buttonContainer}>
      <a href={button?.link} className={styles.button} target="_blank" rel="noreferrer noopener" aria-label={button.label}>{button?.label}</a>
      {/* <button onClick={() => window.location.href='https://www.autryportal.com/Account/Login/Register'} className={styles.button} aria-label={button.label}>{button.label}</button> */}
    </div>
  )
}
