import parse from 'html-react-parser'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import SlantedBorder from "../slantedBorder"
import styles from './oneColumnText.module.scss'

type OneColumnTextProps = {
  block: Record<string, any>
  scroll: number
}

export default ({ block, scroll }: OneColumnTextProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  const colorScheme = block.attrs.color_scheme

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const scrollDown = () => {
    window.scrollTo({ top: (window.innerHeight * .85) + window.scrollY, behavior: 'smooth' })
  }

  return (
    <section
      className={styles.oneColumnText + ` ${show ? styles.show : ''}` +
        ` ${colorScheme ? styles.light : ''}`}
      ref={ref}>
      {block.attrs.heading &&   
     <h4 className={styles.heading}>{parse(block.attrs.heading)}</h4>}
      {block.attrs.body &&
     <p className={styles.body}>{parse(block.attrs.body)}</p>}
      {block.attrs.cta_text &&
     <div className={styles.ctaContainer}>
       {block.attrs.cta_link && !block.attrs.external &&
        <Link className={styles.cta} to={block.attrs.cta_link}>
          <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
          <div className={styles.arrow} />
        </Link>}
       {!block.attrs.cta_link &&
        <button className={styles.cta} onClick={scrollDown}>
          <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
          <div className={styles.arrow} />
        </button>}
       {block.attrs.cta_link && block.attrs.external &&
        <a target="_blank" rel="noopener noreferrer" className={styles.cta} href={block.attrs.cta_link}>
          <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
          <div className={styles.arrow} />
        </a>}
      </div>}
      {block.attrs.has_slanted_border && block.attrs.slanted_border &&
      <SlantedBorder {...JSON.parse(block.attrs.slanted_border)} />}
    </section>
  )
}