import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { Form, Job } from '..'
import { getPath } from '../../../../utilities/getPath'
import BaseForm, { TextField } from "../baseForm"
import styles from "./editJobForm.module.scss"

type JobFormProps = {
  block: Record<string, any>
  setForm: React.Dispatch<Form>
  setUser: React.Dispatch<any>
  user: any
  jobs: Job[]
  getJobs: () => void
}

export default ({ block, setForm, setUser, user, jobs, getJobs}: JobFormProps) => {
  const initialValues: Record<string, any> = {
    job_title: jobs[0].job_title,
    job_type: jobs[0].job_type,
    location: jobs[0].location,
    url: jobs[0].url
  }

  const [formValues, setFormValues] = useState<Record<string, any>>(initialValues);

  const [currentJob, setCurrentJob] = useState<Job>(jobs[0])

  useEffect(() => {
    setFormValues({
      job_title: currentJob.job_title,
      job_type: currentJob.job_type,
      location: currentJob.location,
      url: currentJob.url
    })
  }, [currentJob])

  const [message, setMessage] = useState<string>("")

  const fields: TextField[] = [
    { label: "Job Title", name: "job_title" },
    { label: "Job Type", name: "job_type" },
    { label: "Location", name: "location" },
    { label: "Job Url", name: "url" },
  ]

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setMessage("");
    e.preventDefault();
    if (user?.data?.user_email) {
      window.fetch(`${getPath()}/wp-json/api/v2/edit-jobpost`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...formValues,
          email: user.data.user_email,
          url: formValues.url.startsWith("http") ? formValues.url : `http://${formValues.url}`,
          id: currentJob.id,
          message: block.attrs.post_email
        })
      }).then(res => {
        if (res.status === 200) {
          setMessage("Job updated!")
          getJobs()
        } else {
          setMessage("There was an error editing the job post.")
        }
      })
    }
  }
  
  const userJobs = jobs.filter(x => x.email === user?.data?.user_email)

  const deleteJob = () => {
    window.fetch(`${getPath()}/wp-json/api/v2/delete-jobpost`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: currentJob.id
      })
    }).then(res => {
      if (res.status === 200) {
        getJobs()
      } else {
        setMessage("There was an error editing the job post.")
      }
    })
  }

  const buttons = [
    { label: "Back", callback: () => setForm("Job") },
    { label: "Delete", callback: deleteJob },
  ]

  useEffect(() => {
    if (!(userJobs.length > 0)) {
      setForm("Job")
    }
  }, [userJobs])

  return (
    <div className={styles.editJob}>
      {userJobs.map((job: Job, i: number) => (
        <div className={styles.jobPost} key={i}>
          <button
            className={job.id === currentJob.id ? styles.active : ''}
            onClick={() => setCurrentJob(job)}
          >
            <span>{job.job_title}</span>
            <span>Posted: {moment(job.date).format("MM/DD/YYYY")}</span>
          </button>
        </div>
      ))}
      <BaseForm
        {...{
          message,
          fields,
          handleSubmit, 
          formValues,
          setFormValues,
          setForm,
          buttons,
          submitLabel: "Save"
        }}
      />
    </div>
  )
}
