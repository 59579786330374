import React from 'react'
import styles from './textField.module.scss'

type TextFieldProps = {
  setValues: React.Dispatch<any>
  values: any
  name: string
  label: string
  required: boolean
}

export default ({ name, label, values, required, setValues }: TextFieldProps) => {
  return (
    <div
      className={styles.textField}>
      <label htmlFor={name}>{label}{required ? ' *' : ''}</label>
      <input
        name={name}
        value={values[name] ?? ''}
        onChange={e => setValues({ ...values, [name]: e.target.value })} />
    </div>
  )
}