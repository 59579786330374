import apiFetch from '@wordpress/api-fetch'
import parse from 'html-react-parser'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { getPath } from '../../../utilities/getPath'
import styles from './testimonials.module.scss'
import TextScroller from "./textScroller"

type TestimonialsProps = {
  block: Record<string, any>
  scroll: number
}

export type Testimonial = {
  name: string
  testimonial: string
  job_title: string
}

export default ({ block, scroll }: TestimonialsProps) => {
  const [ testimonials, setTestimonials] = useState<any>([])
  const [direction, setDirection] = useState<'up' | 'down' | 'unset'>('unset')
  const [pause, setPause] = useState<boolean>(false)
  const [show, setShow] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const colorScheme = block.attrs.color_scheme
  const [current, setCurrent] = useState<Testimonial | null>(null)
  const [showDetails, setShowDetails] = useState(false);
  const sortTestimonials = (a: Testimonial, b: Testimonial) => 
    a.name < b.name ? -1 : 1

  useEffect(() => {
    apiFetch({ path: `${getPath()}/wp-json/wp/v2/testimonials?per_page=100`}).then((res: any) => {
      if (res && res.length) {
        res.sort(sortTestimonials)
      }
      setTestimonials(res) 
      setCurrent(res[0])
      setTimeout(() => setShowDetails(true), 50)
    })

    setTimeout(() => {
      if (ref.current && ref.current.getBoundingClientRect().top < 200)
      setShow(true)
    }, 50)

  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5) &&
      ref.current.getBoundingClientRect().bottom > (window.innerHeight * .25)) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
  }, [scroll])
  
  const onClick = (staff: Testimonial) => {
    if (!current || current.testimonial !== staff.testimonial)
    setShowDetails(false)
    setTimeout(() => {
      setCurrent(staff)
      setShowDetails(true)
    }, 50)
  }

  const scrollDown = () => {
    window.scrollTo({ top: (window.innerHeight * .85) + window.scrollY, behavior: 'smooth' })
  }

  return (
    <section
      className={styles.testimonials + ` ${show ? styles.show : ''}` +
        ` ${colorScheme ? styles.light : ''}`}
      ref={ref}>
      <div
        className={styles.left}
        onMouseEnter={() => setPause(true)}
        onMouseLeave={() => setPause(false)}>
        <div className={styles.topPadding} />
        <div
          className={styles.topOverlay}
        />
        <h3 className={styles.heading}>{block.attrs.heading}</h3>
        <button className={styles.topArrow}
          onClick={() => { setDirection('up'); }} />
        {testimonials && testimonials.length && show &&
        <TextScroller {...{ setDirection, direction, testimonials, pause, setPause, onClick }} />}
        <div
          className={styles.bottomOverlay}
        />
        <div className={styles.bottomArrow}
          onClick={() => { setDirection('down');  }} />
        
      </div>
      <div className={styles.right}>
        {current &&
        <div className={styles.details + ` ${showDetails ? styles.show : ''}`}>
          <div className={styles.testimonialText}>{parse(current.testimonial)}</div>
          <p className={styles.name}>{current.name} - {current.job_title}</p>
        </div>}
        {block.attrs.cta_text &&
        <div className={styles.ctaContainer}>
          {block.attrs.cta_link?.startsWith("/") &&
          <Link className={styles.cta} to={block.attrs.cta_link}>
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </Link>}
          {!block.attrs.cta_link &&
          <button className={styles.cta} onClick={scrollDown}>
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </button>}
          {block.attrs.cta_link && !block.attrs.cta_link?.startsWith("/") &&
          <a className={styles.cta} href={block.attrs.cta_link} target="_blank" rel="noopener noreferrer">
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </a>}
        </div>}
      </div>
    </section>
  )
}