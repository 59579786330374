import React, { useState } from 'react'
import { Form, Job } from '..'
import { getPath } from '../../../../utilities/getPath'
import BaseForm, { TextField } from "../baseForm"

type JobFormProps = {
  block: Record<string, any>
  setForm: React.Dispatch<Form>
  setUser: React.Dispatch<any>
  user: any
  jobs: Job[]
  getJobs: () => void
}

export default ({ block, setForm, setUser, user, jobs, getJobs }: JobFormProps) => {
  let initialValues: Record<string, any> = {
    job_title: '',
    job_type: '',
    location: '',
    url: ''
  }

  const [formValues, setFormValues] = useState<Record<string, any>>(initialValues);
  const [message, setMessage] = useState<string>("")

  const fields: TextField[] = [
    { label: "Job Title", name: "job_title" },
    { label: "Job Type", name: "job_type" },
    { label: "Location", name: "location" },
    { label: "Job Url", name: "url" },
  ]

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setMessage("");
    e.preventDefault();
    if (user?.data?.user_email) {
      window.fetch(`${getPath()}/wp-json/api/v2/jobpost`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...formValues,
          email: user.data.user_email,
          url: formValues.url.startsWith("http") ? formValues.url : `http://${formValues.url}`,
          message: block.attrs.post_email
        })
      }).then(res => {
        if (res.status === 200) {
          setMessage(block.attrs.post_submit)
          setFormValues(initialValues)
          getJobs()
        } else {
          setMessage("There was an error submitting the job post.")
        }
      })
    }
  }

  const logout = () => {
    window.fetch(`${getPath()}/wp-json/api/v2/logout-job-post`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
    }).then(res => {
      if (res.status === 200) {
        setForm("Login")
        setUser(null)
      } else {
        setMessage("There was an error.")
      }
    })
  }

  const buttons = [
    { label: "Log Out", callback: logout },
    { label: "Change Password", callback: () => setForm("Change Password") },
  ]

  const userJobs = jobs.filter(x => x.email === user?.data?.user_email)

  if (userJobs.length > 0) {
    buttons.push({ label: "Edit jobs", callback: () => setForm("Edit Job") })
  }

  return (
    <BaseForm
      {...{
        message,
        fields,
        handleSubmit, 
        formValues,
        setFormValues,
        setForm,
        buttons,
        submitLabel: "Submit"
      }}
    />
  )
}
