import React from 'react'
import { Link } from 'react-router-dom'
import { MainLink } from '../../header'

const styles = require('./poweredByLogo.module.scss')

type props = {
  logo: string
  setMega: React.Dispatch<MainLink | null>
  link: string
}

export default ({ logo, setMega, link }: props) => {
  return <a
    className={styles.logo}
    target='_blank' rel='noopener noreferrer'
    style={{ backgroundImage: `url(${logo})` }} href={link ? link : '/'}
    onClick={() => setMega(null)}
    aria-label="Navigate to Homepage"
    />
}
