import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { MainLink } from '../../header'
import styles from './links.module.scss'
import { Context } from '../../../../context'
import { trimLink } from '../../../../utilities/trimLink'

type props = {
  links: MainLink[]
  setMega: React.Dispatch<MainLink | null>
}

export default ({ links, setMega }: props) => {
  const context = useContext(Context)
  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    link: MainLink
  ) => {
    if (link.has_megamenu) {
      e.preventDefault()
      setMega(link)
    }
  }

  const parseLink = (path: string | undefined) => {
    if (path) {
      const parsedPath = trimLink(path).split('-')
      return parsedPath[0].endsWith('/') ? parsedPath[0].slice(0, parsedPath[0].length - 1) : parsedPath[0]
    }
    return ''
  }

  const isSubPage = (link: MainLink) =>
    parseLink(link.page) === parseLink(window.location.pathname)

  const handleLinkClick = (page: string) => {
    if (!context) return

    if (page.includes('high-school')) context.setResourcesNav('HS')

    if (page.includes('adult')) context.setResourcesNav('Adult')
  }

  return (
    <div className={styles.links}>
      {links && links.map((link, i) => (
        <NavLink
          key={i}
          className={styles.link + 
            ` ${isSubPage(link) ? styles.active : ''}`}
          activeClassName={styles.active}
          to={link?.page ? trimLink(link.page) : ''}
          onClick={e => { handleLinkClick(link?.page ?? ''); handleClick(e, link) }}
        >
          {link.label}
        </NavLink>
      ))}
    </div>
  )
}
