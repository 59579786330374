import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../context'

import { getPath } from '../../utilities/getPath'
import { Button, CallButton, DropdownForm, Links, Logo, MegaMenu, Menu, MenuButton, PoweredByLogo, SearchBar, SearchToggle, SubNav, TopLinks } from './components'
import styles from './header.module.scss'

export type PageLink = {
  label: string
  page: string
  url: string
}

type Checkbox = {
  option: string
}

type Form = {
  checkboxes: Checkbox[]
  submitTo: string
}

type HeaderMenu = {
  logo: string
  button: { label: string; link: string }
  links: MainLink[]
  top_links: PageLink[]
  call_button: { number_display: string; number_link: string; icon: string }
  dropdownForm: Form
  powered_by_logo: string
  powered_by_link: string
}

export type MainLink = {
  label: string
  page?: string
  has_megamenu: boolean
  sub_pages?: SubPage[]
  show_funnels: boolean,
  image: string,
  text: string,
}

export type SubPage = {
  is_page_group: boolean
  label: string
  page?: string
  pages?: PageLink[]
  url: string,
}

export default () => {
  const [headerMenu, setHeaderMenu] = useState<HeaderMenu>({
    logo: '',
    button: { label: '', link: '' },
    links: [{ label: '', page: '', has_megamenu: false, show_funnels: true, image: '', text: '' }],
    top_links: [{ label: '', page: '', url: '' }],
    call_button: { number_link: '', number_display: '', icon: ''},
    dropdownForm: { checkboxes: [], submitTo: ''},
    powered_by_logo: '',
    powered_by_link: ''
  })

  const [mega, setMega] = useState<MainLink | null>(null)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [searchToggle, setSearchToggle] = useState<'Site' | 'Course'>('Course');
  const context = useContext(Context)

  useEffect(() => {
    window
      .fetch(`${getPath()}/wp-json/menus/v1/menus/header`)
      .then(res => res.json())
      .then(json => setHeaderMenu(json))
  }, [])

  const formShow = context?.dropdownOpen
  const setFormShow = context?.setDropdownOpen

  return (
    <header className={styles.header}>
      <DropdownForm form={headerMenu.dropdownForm} {...{ formShow, setFormShow }} />
      <nav className={styles.topNav}>
        <PoweredByLogo
          logo={headerMenu.powered_by_logo}
          link={headerMenu.powered_by_link}
          {...{ setMega }} />
        <SearchToggle {...{ searchToggle, setSearchToggle }} />
        <SearchBar { ...{ setMenuOpen, searchToggle }} />
        <TopLinks links={headerMenu.top_links} {...{ setMega }}/>
        <CallButton {...{ ...headerMenu.call_button }} {...{ setMega }} />
      </nav>
      <div className={styles.mainNav}>
        <Logo logo={headerMenu.logo} { ...{ setMega, setMenuOpen }}/>
        <nav className={styles.nav}>
          <Links links={headerMenu.links} {...{ mega, setMega}} />
          <Button button={headerMenu.button} {...{ setMega, setFormShow }} />
          <MenuButton {...{ menuOpen, setMenuOpen }} />
          <MegaMenu {...{ mega, setMega }} />
        </nav>
      </div>
        {/* <SubNav links={headerMenu?.links} /> */}
      <Menu
        links={headerMenu.links}
        topLinks={headerMenu.top_links} 
        { ...{ setMenuOpen, menuOpen, searchToggle, setSearchToggle }}
      />
    </header>
  )
}
