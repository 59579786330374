import apiFetch from '@wordpress/api-fetch'
import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { getPath } from "../../../utilities/getPath"
import TextScroller from "../horizonalTextScroller"
import SlantedBorder from "../slantedBorder"
import styles from "./tenFunnels.module.scss"

type TenFunnelsProps = {
  block: Record<string, any>
  scroll: number
}

type Funnel = {
  name: string
  pagelink: string
}

export default ({ block, scroll }: TenFunnelsProps) => {
  const [ programs, setPrograms ] = useState<any>([])
  const ref = useRef<HTMLDivElement>(null)
  const [hide, setHide] = useState(true)
  const [show, setShow] = useState(false)

  useEffect(() => {
    apiFetch({ path: `${getPath()}/wp-json/wp/v2/funnels?per_page=100`}).then(res => setPrograms(res))
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setHide(true)
      setHide(false)
    }, 120000)

    setTimeout(() => {
      setHide(false)
      setShow(true)
    }, 50)

    return (() => clearInterval(interval))
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5) &&
      ref.current.getBoundingClientRect().bottom > (window.innerHeight * .25)) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
  }, [scroll])

  const attributes = programs.map((x: Funnel) => ({
    text: x.name,
    link: x.pagelink
  }))

  const scrollDown = () => {
    window.scrollTo({ top: (window.innerHeight * .85) + window.scrollY, behavior: 'smooth' })
  }

  return (
    <section className={styles.tenFunnels} ref={ref}>
      <div
        className={styles.backgroundImage}
        role='img'
        aria-label={block.attrs.background_image_alt_text}
        style={{ backgroundImage: `url(${block.attrs.background_image_url})` }} />
      <div className={styles.overlay} />
      {!hide &&
      <div className={styles.scrollers + ` ${show ? styles.show : ''}`} >
        <TextScroller direction='left' duration={400} {...{ attributes, styles, scroll }} />
        <TextScroller direction='right' duration={480} {...{ attributes, styles, scroll }} />
        <TextScroller direction='left' duration={640} {...{ attributes, styles, scroll }} />
        <div className={styles.desktopScrollers}>
          <TextScroller direction='right' duration={640} {...{ attributes, styles, scroll }} />
          <TextScroller direction='left' duration={520} {...{ attributes, styles, scroll }} />
          <TextScroller direction='right' duration={800} {...{ attributes, styles, scroll }} />
        </div>
      </div>}
      {block.attrs.cta_text &&
      <div className={styles.ctaContainer}>
        {block.attrs.cta_link?.startsWith("/") &&
        <Link className={styles.cta} to={block.attrs.cta_link}>
          <p>{block.attrs.cta_text}</p>
          <div className={styles.arrowContainer}>
            <div className={styles.arrow} />
          </div>
        </Link>}
        {!block.attrs.cta_link &&
        <button className={styles.cta} onClick={scrollDown}>
          <p>{block.attrs.cta_text}</p>
          <div className={styles.arrowContainer}>
            <div className={styles.arrow} />
          </div>
        </button>}
        {block.attrs.cta_link && !block.attrs.cta_link?.startsWith("/") &&
        <a className={styles.cta} href={block.attrs.cta_link} target="_blank" rel="noopener noreferrer">
          <p>{block.attrs.cta_text}</p>
          <div className={styles.arrowContainer}>
            <div className={styles.arrow} />
          </div>
        </a>}
      </div>}
      {!block.cta_text && <div  className={styles.filler} />}
      {block.attrs.has_slanted_border && block.attrs.slanted_border &&
      <SlantedBorder {...JSON.parse(block.attrs.slanted_border)} />}
    </section>
  )
}