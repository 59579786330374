import React, { useEffect, useRef, useState } from "react"
import styles from "./gallery.module.scss"

type GalleryProps = {
  block: Record<string, any>
  scroll: number
}

type Image = {
  image_url: string
  image_alt_text: string
  image_text: string
}

export default ({ block, scroll }: GalleryProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [currentImage, setCurrentImage] = useState<number>(0)
  const images = block.attrs.items

  const allImages: { url: string; text: string }[] = [
    { url: block.attrs.feature_image_url, text: block.attrs.image_text },
    ...images.map((x: Image) => ({ url: x.image_url, text: x.image_text }))
  ]

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const handleOpen = (i: number) => {
    setModalOpen(true)
    setCurrentImage(i)
  }
  
  const imageRight = () => {
    setCurrentImage(
      currentImage === allImages.length - 1
        ? 0 
        : currentImage + 1
    )
  }
  
  const imageLeft = () => {
    setCurrentImage(
      currentImage === 0
        ? allImages.length - 1
        : currentImage - 1
    )
  }

  return (
    <section className={styles.gallery + ` ${show ? styles.show : ''}`} ref={ref}>
      <h4 className={styles.heading}>{block.attrs.heading}</h4>
      <div className={styles.content}>
        <button className={styles.featured} onClick={() => handleOpen(0)}>
          <img
            src={block.attrs.feature_image_url}
            alt={block.feature_image_alt_text ?? ''}
          />
        </button>
        <div className={styles.grid}>
          {images && images.map((image: Image, i: number) => (
            <button 
              key={i}
              className={styles.gridImage}
              onClick={() => handleOpen(i + 1)}
              style={{ transitionDelay: `${i * .2}s` }}
            >
              <img
                src={image.image_url}
                alt={image.image_alt_text ?? ''}
              />
            </button>
          ))}
        </div>
        <div className={styles.arrowUp} />
        <div className={styles.arrowDown} />
      </div>
      <div className={styles.modal + ` ${modalOpen ? styles.open : ''}`}>
        <div className={styles.modalContainer}>
          <div className={styles.count}>
            {`${currentImage + 1} / ${allImages.length}`}
          </div>
          <img src={allImages[currentImage].url ?? ''} alt="" />
          {allImages[currentImage].text &&
          <p className={styles.description}>{allImages[currentImage].text}</p>}
          <button className={styles.close} onClick={() => setModalOpen(false)}>x</button>
        </div>
        <div className={styles.arrowLeft} onClick={imageLeft} />
        <div className={styles.arrowRight} onClick={imageRight} />
      </div>
    </section>
  )
}