import React, { useState } from "react";
import { getPath } from "../../../../utilities/getPath";

const styles = require("./dropdownForm.module.scss");

type Checkbox = {
  option: string;
};

type Props = {
  form: {
    checkboxes: Checkbox[];
    submitTo: string;
  };
  setFormShow: React.Dispatch<boolean>;
  formShow: boolean;
};

type InitialValues = {
  name: string;
  question: string;
  email: string;
  phone: string;
  interested: Record<string, any>;
};

export default ({ form, setFormShow, formShow }: Props) => {
  const initialValues: InitialValues = {
    name: "",
    question: "",
    email: "",
    phone: "",
    interested: {},
  };

  form.checkboxes.forEach((option) => {
    initialValues.interested[option.option] = false;
  });
  const [formValues, setFormValues] =
    useState<Record<string, any>>(initialValues);
  const [formMessage, setFormMessage] = useState<string>("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setFormMessage("");
    e.preventDefault();
    window
      .fetch(`${getPath()}/wp-json/api/v2/getstarted`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...formValues,
        }),
      })
      .then((res) => {
        if (res.status === 200) {
          setFormMessage("Thank you! We'll be in touch.");
          setFormValues(initialValues);
        } else {
          setFormMessage("There was an error submitting the form.");
        }
      });
  };

  const handleCheckBox = (val: string) => {
    if (formValues.interested[val]) {
      setFormValues({
        ...formValues,
        interested: {
          ...formValues.interested,
          [val]: false,
        },
      });
    } else {
      setFormValues({
        ...formValues,
        interested: {
          ...formValues.interested,
          [val]: true,
        },
      });
    }
  };

  return (
    <div className={styles.dropdownForm + ` ${formShow ? styles.show : ""}`}>
      <button className={styles.close} onClick={() => setFormShow(false)}>
        x
      </button>
      <form onSubmit={handleSubmit}>
        <div className={styles.textField}>
          <input
            name="name"
            value={formValues.name}
            className={styles.textField}
            placeholder={"Name"}
            required
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
          />
        </div>
        <div className={styles.textField}>
          <input
            name="email"
            value={formValues.email}
            className={styles.textField}
            placeholder={"Email"}
            required
            onChange={(e) =>
              setFormValues({ ...formValues, email: e.target.value })
            }
          />
        </div>
        <div className={styles.textField}>
          <input
            name="phone"
            value={formValues.phone}
            className={styles.textField}
            placeholder={"Phone"}
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            required
            onChange={(e) =>
              setFormValues({ ...formValues, phone: e.target.value })
            }
          />
        </div>
        <div className={styles.checkboxListField}>
          <fieldset>
            <legend>What are you interested in?</legend>
            {form.checkboxes &&
              form.checkboxes.map((option: Checkbox, i: number) => (
                <div className={styles.checkboxField} key={option.option}>
                  <input
                    id={option.option}
                    name={option.option}
                    type="checkbox"
                    tabIndex={0}
                    checked={formValues.interested[option.option]}
                    onChange={() => handleCheckBox(option.option)}
                  />
                  <label htmlFor={option.option} tabIndex={0}>
                    <span
                      className={
                        styles.box +
                        ` ${
                          formValues.interested[option.option]
                            ? styles.active
                            : ""
                        }`
                      }
                    ></span>
                    <span>{option.option}</span>
                  </label>
                </div>
              ))}
          </fieldset>
        </div>
        <div className={styles.message}>
          <textarea
            name="question"
            required
            value={formValues.question}
            className={styles.message}
            placeholder={"Question?"}
            onChange={(e) =>
              setFormValues({ ...formValues, question: e.target.value })
            }
          />
        </div>
        <div className={styles.formMessage}>{formMessage}</div>
        <div className={styles.ctaContainer}>
          <button className={styles.cta}>
            <p className={styles.buttonLabel}>Send</p>
            <div className={styles.arrow} />
          </button>
        </div>
      </form>
    </div>
  );
};
