import apiFetch from '@wordpress/api-fetch'
import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { getPath } from "../../../utilities/getPath"
import CheckboxField from "./components/checkboxField"
import Computers from "./components/computers"
import Slider from './components/slider'
import styles from './rooms.module.scss'

type GridProps = {
  block: Record<string, any>
  scroll: number
}

export type Filters = {
  capacity: number
  computers: "Yes" | "No" | "Presenter only" | null
  presenting: string[]
}

type Room = {
  capacity: number
  computers: string
  presenting: string[]
  name: string
  image: string
  link: string
  slug: string
}

export default ({ block, scroll }: GridProps) => {
  const [show, setShow] = useState<boolean>(false)
  const [rooms, setRooms] = useState<any>([])
  const ref = useRef<HTMLDivElement>(null)
  const [filters, setFilters] = useState<Filters>({
    capacity: 0,
    computers: null,
    presenting: []
  })

  const presenting = block.attrs.presenting

  useEffect(() => {
    apiFetch({ path: `${getPath()}/wp-json/wp/v2/rooms?per_page=100`}).then(res => setRooms(res))

    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
        setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const filterPresenting = (room: Room) => {
    if (filters.presenting.length === 0) {
      return true;
    }

    let include = true
    filters.presenting.forEach(option => {
      if (!room.presenting.includes(option)) {
        include = false;
      }
    })
    return include;
  }

  const filterRoom = (room: Room) => {

    if (+room.capacity >= +filters.capacity &&
      filterPresenting(room) &&
      (room.computers === filters.computers || !filters.computers)
    ) {
      return room
    }
  }

  const sorted = [...rooms].sort((a: Room, b: Room) => b.name > a.name ? -1 : 1)

  return (
    <section className={styles.rooms + ` ${show ? styles.show : ''}`} ref={ref}>
      <div className={styles.left}>
        <h2 className={styles.heading}>{block.attrs.heading}</h2>
        <Slider {...{ filters, setFilters }} />
        <Computers {...{ filters, setFilters }} />
        <fieldset className={styles.checkboxes}>
          <legend>Amenities</legend>
          {presenting && presenting.map((name: string, i: number) => (
            <CheckboxField
              key={i}
              {...{ filters, setFilters, name }}
            />
          ))}
        </fieldset>
        <button className={styles.viewAll} onClick={() => {
          setFilters({
            capacity: 0,
            computers: null,
            presenting: []
          })
        }}>
          {`View All(${rooms.length})`}
        </button>
      </div>
      <div className={styles.right}>
        {sorted && sorted.length > 0 && sorted.filter(filterRoom).map((room: Room, i: number) => (
          <Link key={i} to={`/rooms/${room.slug}`} className={styles.room}>
            <img src={room.image} alt={room.name}/>
            <div className={styles.gradient} />
            <p>{room.name}</p>
          </Link>
        ))}
      </div>
      
    </section>
  )
}