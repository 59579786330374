import React, { useEffect, useState, useRef } from "react"
import styles from "./sessionBreakdown.module.scss"
import moment from "moment"
import { Link } from "react-router-dom"

type SessionBreakdownProps = {
  block: Record<string, any>
  scroll: number
}

type Program = {
  title: string
  morning_start: string
  morning_end: string
  afternoon_start: string
  afternoon_end: string
}

export default ({ block, scroll}: SessionBreakdownProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && ref.current.getBoundingClientRect().top < 200)
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .35) &&
        ref.current.getBoundingClientRect().bottom > (window.innerHeight * .50)) {
        setShow(true);
      }
    }
  }, [scroll])

  const programs = block?.attrs?.programs

  const calculateWidth = (start: string, end: string) => {
    const startMs = new Date(start).getTime()
    const endMs = new Date(end).getTime()
    const diff = endMs - startMs
    const totalMs = 3600 * 1000 * 10
    return diff / totalMs * 100 * .725
  }
  
  const calculateMobileWidth = (start: string, end: string) => {
    const startMs = new Date(start).getTime()
    const endMs = new Date(end).getTime()
    const diff = endMs - startMs
    const totalMs = 3600 * 1000 * 4
    return diff / totalMs * 100 * .59
  }

  const calculateLeft = (start: string) => {
    const startMs = new Date(start).getTime()
    const dayStart = new Date(startMs)
    dayStart.setHours(8)
    dayStart.setMinutes(0)
    dayStart.setSeconds(0)
    const totalMs = 3600 * 1000 * 10
    const diff = startMs - dayStart.getTime()
    return diff / totalMs * 100 * .725
  }

  const calculateMobileMorningLeft = (start: string) => {
    const startMs = new Date(start).getTime()
    const dayStart = new Date(startMs)
    dayStart.setHours(8)
    dayStart.setMinutes(0)
    dayStart.setSeconds(0)
    const totalMs = 3600 * 1000 * 4
    const diff = startMs - dayStart.getTime()
    return diff / totalMs * 100 * .59
  }
  
  const calculateMobileAfternoonLeft = (start: string) => {
    const startMs = new Date(start).getTime()
    const dayStart = new Date(startMs)
    dayStart.setHours(8)
    dayStart.setMinutes(0)
    dayStart.setSeconds(0)
    const totalMs = 3600 * 1000 * 4
    const diff = startMs - totalMs - dayStart.getTime()
    return diff / totalMs * 100 * .59
  }

  const scrollDown = () => {
    window.scrollTo({ top: (window.innerHeight * .85) + window.scrollY, behavior: 'smooth' })
  }
  return (
    <section className={styles.sessionBreakdown} ref={ref}>
      <h2 className={styles.heading}>{block.attrs.heading}</h2>
      <p className={styles.body}>{block.attrs.body}</p>
      <div className={styles.sessionHeadings}>
        <span>Morning Sessions</span>
        <span>Afternoon Sessions</span>
      </div>
      <div className={styles.programs}>
        {programs && programs.map((program: Program) => (
          <div key={program.title} className={styles.program}>
            <h5 className={styles.programTitle}>{program.title}</h5>
            <div className={styles.container}>
              <div
                className={styles.morning}
                style={{
                  width: `${show ? `${calculateWidth(program.morning_start, program.morning_end)}%` : '0'}`,
                  left: `${calculateLeft(program.morning_start)}%`
                }}
              >
                <span className={styles.time + ` ${show ? styles.show : ''}`}>
                  {moment(program.morning_start).format('h:mm')}
                </span>
                <span className={styles.time + ` ${show ? styles.show : ''}`}>
                  {moment(program.morning_end).format('h:mm')}
                </span>
              </div>
              <div
                className={styles.afternoon}
                style={{
                  width: `${show ? `${calculateWidth(program.afternoon_start, program.afternoon_end)}%` : '0'}`,
                  left: `${calculateLeft(program.afternoon_start)}%`
                }}
              >
                <span className={styles.time + ` ${show ? styles.show : ''}`}>
                  {moment(program.afternoon_start).format('h:mm')}
                </span>
                <span className={styles.time + ` ${show ? styles.show : ''}`}>
                  {moment(program.afternoon_end).format('h:mm')}
                </span>
              </div>
            </div>
          </div>
        ))}
        <div className={styles.timeColumns}>
          <div><p>8:00</p></div>
          <div><p>10:00</p></div>
          <div><p>12:00</p></div>
          <div><p>2:00</p></div>
          <div><p>4:00</p></div>
        </div>
      </div>
      <div className={styles.mobileSessionHeadings}>
        <span>Morning Sessions</span>
      </div>
      <div className={styles.programsMobile}>
        {programs && programs.map((program: Program) => (
          <div key={program.title} className={styles.program}>
            <h5 className={styles.programTitle}>{program.title}</h5>
            <div className={styles.container}>
              <div
                className={styles.morning}
                style={{
                  width: `${show ? `${calculateMobileWidth(program.morning_start, program.morning_end)}%` : '0'}`,
                  left: `${calculateMobileMorningLeft(program.morning_start)}%`
                }}
              >
                <span className={styles.time + ` ${show ? styles.show : ''}`}>
                  {moment(program.morning_start).format('h:mm')}
                </span>
                <span className={styles.time + ` ${show ? styles.show : ''}`}>
                  {moment(program.morning_end).format('h:mm')}
                </span>
              </div>
            </div>
          </div>
        ))}
        <div className={styles.timeColumns}>
          <div><p>8:00</p></div>
          <div><p>10:00</p></div>
          <div><p>12:00</p></div>
        </div>
        
      </div>
      <div className={styles.mobileSessionHeadings}>
        <span>Afternoon Sessions</span>
      </div>
      <div className={styles.programsMobile}>
        {programs && programs.map((program: Program) => (
          <div key={program.title} className={styles.program}>
            <h5 className={styles.programTitle}>{program.title}</h5>
            <div className={styles.container}>
              <div
                className={styles.afternoon}
                style={{
                  width: `${show ? `${calculateMobileWidth(program.afternoon_start, program.afternoon_end)}%` : '0'}`,
                  left: `${calculateMobileAfternoonLeft(program.afternoon_start)}%`
                }}
              >
                <span className={styles.time + ` ${show ? styles.show : ''}`}>
                  {moment(program.afternoon_start).format('h:mm')}
                </span>
                <span className={styles.time + ` ${show ? styles.show : ''}`}>
                  {moment(program.afternoon_end).format('h:mm')}
                </span>
              </div>
            </div>
          </div>
        ))}
        <div className={styles.timeColumns}>
          <div><p>12:00</p></div>
          <div><p>2:00</p></div>
          <div><p>4:00</p></div>
        </div>
      </div>
      {block.attrs.cta_text &&
      <div className={styles.ctaContainer}>
        {block.attrs.cta_link && block.attrs.startsWith('/') &&
        <Link className={styles.cta} to={block.attrs.cta_link}>
          <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
          <div className={styles.arrow} />
        </Link>}
        {block.attrs.cta_link && !block.attrs.startsWith('/') &&
        <a className={styles.cta} href={block.attrs.cta_link}>
          <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
          <div className={styles.arrow} />
        </a>}
        {!block.attrs.cta_link &&
        <button className={styles.cta} onClick={scrollDown}>
          <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
          <div className={styles.arrow} />
        </button>}
      </div>}
    </section>
  )
}