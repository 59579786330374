import React from 'react'
import { Filters } from '../..'
import styles from './computers.module.scss'

type Props = {
  filters: Filters
  setFilters: React.Dispatch<Filters>
}

type ComputerVal = "Yes" | "No" | "Presenter only" | null

type ButtonProps = {
  val: ComputerVal
  update: (val: ComputerVal) => void
  active: boolean
}

const Button: React.FC<ButtonProps> = ({ val, update, active }) => (
  <button
    className={active ? styles.active : ""}
    onClick={() => update(val)}>
    {val}
  </button>
)

export default (props: Props) => {
  const { filters, setFilters } = props

  const updateComputers = (val: ComputerVal) => {
    setFilters({...filters, computers: val})
  }

  const options: ComputerVal[] = ["Yes", "No", "Presenter only"]

  return (
    <fieldset className={styles.computerButtons}>
      <legend>Do you need computers?</legend>
      <div className={styles.buttons}>
        {options.map((option: ComputerVal, i: number) => (
          <Button
            key={i}
            val={option}
            update={updateComputers}
            active={filters.computers === option}
          />
        ))}
      </div>
    </fieldset>
  )
}