import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import styles from './fourToSixPicker.module.scss'

type GridProps = {
  block: Record<string, any>
  scroll: number
}

type Item = {
  name: string
  image_url: string
  link: string
}

export default ({ block, scroll }: GridProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  const items = block.attrs.items

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])


  return (
    <section className={styles.picker + ` ${show ? styles.show : ''}`} ref={ref}>
      <div className={styles.background} />
      <h4 className={styles.heading}>{block.attrs.heading}</h4>
      <p className={styles.subheading}>{block.attrs.subheading}</p>
      <div className={styles.items}>
        {items && items.map((item: Item, i: number) => (
          <div className={styles.item} key={i}>
            {item.link?.startsWith("/") &&
            <Link
              key={i}
              to={item.link}
              style={{
                transitionDelay: `${.2 + i * .2}s`
              }}
              >
              <img src={item.image_url} alt={`${item.name} Icon`} />
              <p>{item.name}</p>
            </Link>}
            {!item.link?.startsWith("/") &&
            <a
              key={i}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                transitionDelay: `${.2 + i * .2}s`
              }}
              >
              <img src={item.image_url} alt={`${item.name} Icon`} />
              <p>{item.name}</p>
            </a>}
          </div>
          
        ))}
      </div>
    </section>
  )
}