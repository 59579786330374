import apiFetch from "@wordpress/api-fetch";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Links, Logo, Shortcuts } from "./components";

import { getPath } from "../../utilities/getPath";
import styles from "./footer.module.scss";

type FooterProps = {
  scroll: number;
};

export default ({ scroll }: FooterProps) => {
  const [footer, setFooter] = useState<any>({
    address: "",
    copyright: "",
    text: "",
    social_links: [{ label: "", link: "", logo: "" }],
    privacy_policy: { label: "", page: "" },
  });

  useEffect(() => {
    apiFetch({ path: `${getPath()}/wp-json/menus/v1/menus/footer` }).then(
      (res) => setFooter(res)
    );
  }, []);

  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scroll && ref.current) {
      if (
        ref.current.getBoundingClientRect().bottom <
        window.innerHeight * 1.2
      ) {
        setShow(true);
      }
    }
  }, [scroll]);

  return (
    <footer className={styles.footer} ref={ref}>
      <p className={styles.text}>{footer.text}</p>
      <div className={styles.bottom}>
        {footer.copyright ? (
          <section className={styles.copyright}>
            <p className={styles.copyrightText}>{footer.copyright}</p>
          </section>
        ) : null}
        <section className={styles.social}>
          {footer.social_links &&
            footer.social_links.map((link: any, i: number) => (
              <a
                key={i}
                className={styles.link}
                style={{ backgroundImage: `url(${link.logo})` }}
                href={link.link}
                target="_blank"
                rel="noopener noopener noreferrer"
                aria-label="Social Link"
              >
                &nbsp;
              </a>
            ))}
        </section>
        {footer.privacy_policy &&
        footer.privacy_policy.label &&
        footer.privacy_policy.page ? (
          <section className={styles.privacyPolicy}>
            <Link
              className={styles.link}
              to={footer.privacy_policy.page.replace(
                window.location.origin,
                ""
              )}
            >
              {footer.privacy_policy.label}
            </Link>
          </section>
        ) : null}
        <section className={styles.branding}>
          <a
            className={styles.link}
            target="_blank"
            rel="noopener noopener noreferrer"
            href="http://fireupyourbrand.com"
            aria-label="Station8 Link"
          >
            Website by Station8
          </a>
        </section>
      </div>
    </footer>
  );
};
