import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

type TextAttribute = {
  text: string
  image_url?: string
  link?: any
}

type TextScrollerProps = {
  direction: 'left' | 'right'
  duration: number
  attributes: TextAttribute[]
  defaultText?: string
  styles: Record<string, any>
  scroll: number
  index?: number
  currentRow?: number
  setCurrentRow?: React.Dispatch<number | undefined>
}

export default (props: TextScrollerProps) => {
  const { direction, duration, attributes, defaultText } = props
  const [start, setStart] = useState(false)
  const [stop, setStop] = useState(false)
  const [translate, setTranslate] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const [currentWord, setCurrentWord] = useState<number | undefined>(undefined)

  const { styles, index, setCurrentRow } = props

  useEffect(() => {
    const timer = setTimeout(() => setStart(true), 100)
    return (() => clearTimeout(timer))
  }, [props.attributes])

  const translateDirection = direction === 'left' ? '-100%' : '100%'

  const handleClick = (i: number) => {
    setCurrentWord(i)
    // if (setCurrentRow && index) {
    //   setCurrentRow(index)
    // }
    // if (setCurrentRow){
    //   setTimeout(() => {
    //     setCurrentWord(undefined)
    //     if (setCurrentRow) {
    //       setCurrentRow(undefined)
    //     }
    //   }, 4000)
    // }
  }

  const getLink = (attribute: TextAttribute) => {
    if (attribute.link.post_name) {
      return `/programs/${attribute.link?.post_name}`+
        `${window?.location?.pathname?.includes('high-school') ? '?path=hs' : ''}`
    }
    if (attribute.link) {
      return attribute.link.replace(window.location.origin, '')
    }
    return '/'
  }

  const TextGroup: React.FC = () => (
    <>
      {attributes && attributes.map((attribute: TextAttribute, i: number) => (
        <div key={attribute.text}>
          {!attribute.link ? (
            <button
              onClick={() => handleClick(i)}
              className={styles.attribute}>
              <span
                className={styles.attributeText}>
                {attribute.text}.
              </span>
            </button>) : (
            <Link
              to={getLink(attribute)}
              onClick={() => handleClick(i)}
              className={styles.attribute}>
              <span
                className={styles.attributeText}>
                {attribute.text}.
              </span>
            </Link>
          )}
        </div>
      ))}
    </>
  )

  return (
    <div className={styles.attributes}
      style={{
        right: `${direction === 'right' ? 'calc(100% - 100vw)' : ''}`,
        transform: start ? `translateX(${stop ? `${translate}px` : translateDirection})` : '',
        transition: `all ${duration}s linear`,
      }}
      ref={ref}
    >
      {new Array(5).fill(0).map((_, i) => (
        <TextGroup key={i} />
      ))}
    </div>
  )
}