import apiFetch from '@wordpress/api-fetch'
import parse from 'html-react-parser'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Context } from '../../../../context'
import { getPath } from '../../../../utilities/getPath'
import { trimLink } from '../../../../utilities/trimLink'
import { ShortTermCourse } from '../../../components/shortTermCourses'
// import { MainLink } from '../../header'
import styles from './searchBar.module.scss'

type Result = {
  title: string
  value: any[]
}

type Props = {
  setMenuOpen?: React.Dispatch<boolean>
  searchToggle: 'Site' | 'Course'
}
export default ({ setMenuOpen, searchToggle }: Props) => {
  const context = useContext(Context)
  const [pages, setPages] = useState<any[]>([])
  const [search, setSearch] = useState<string>('')
  const [results, setResults] = useState<any>([])
  const history = useHistory();
  history.listen(() => { setResults([]); setSearch('')})
  const [programs, setPrograms] = useState<any>([])
  
  useEffect(() => {
    apiFetch({ path: `${getPath()}/wp-json/wp/v2/programs?per_page=100`}).then(res => {
      if (res) {
        const response = res as any;
        setPrograms(response.map((x: any) => ({ 
          title: parse(x.title.rendered),
          value: [{
            slug: `/programs/${x.slug}`,
            text: parse(x.title.rendered)
          }]
        })))
      }
    })
  }, [])

  useEffect(() => {
    if (context && context.attributes.length === 0) {
      context.setIndexingLoading(true)
      apiFetch({ path: `${getPath()}/wp-json/api/v2/search-indexes`}).then((res: any) => {
        context.setAttributes(res);
        context.setIndexingLoading(false)
      })
    }
    if (!search) {
      setResults([])
    }
    if (search && context?.attributes !== undefined && searchToggle === 'Site') {
      const filtered = context.attributes.filter((x: any) =>
        x.text.toLowerCase().includes(search.toLowerCase()))

      const sorted = _.chain(filtered)
        .groupBy(x => x.title)
        .map((value, key) => ({ title: key, value}))
        .value()

      setResults(sorted)
    }
  
    if (search && context && !context.shortTermLoading && !context.flexLoading && searchToggle === 'Course' && !context.safetyLoading) {
      const shortTermMap = {
        "Agriculture, Food, and Natural Resources": "agriculture-energy",
        "Architecture and Construction": "architecture-construction-short-term-courses",
        "Arts and Communications": "arts-communications-short-term-courses",
        "Business Management and Leadership": "business-management-leadership-short-term-courses",
        "Health and Emergency Services": "health-emergency-services-short-term-courses",
        "Hospitality and Human Services": "hospitality-human-services-short-term-courses",
        "Information Technology": "information-technology-short-term-courses",
        "Manufacturing and Trades": "manufacturing-trades-short-term-courses",
        "Retail": "retail",
        "Transportation": "transportation-short-term-courses",
      } as Record<string, any>
      
      const flexMap = {
        "Agriculture, Food, and Natural Resources": "agriculture-energy-flex-programs",
        "Architecture and Construction": "architecture-construction-flex-programs",
        "Arts and Communications": "arts-communications-flex-programs",
        "Business Management and Leadership": "business-management-leadership-flex-programs",
        "Health and Emergency Services": "health-emergency-services-flex-programs",
        "Hospitality and Human Services": "hospitality-human-services-flex-programs",
        "Information Technology": "information-technology-flex-programs",
        "Manufacturing and Trades": "manufacturing-trades-flex-programs",
        "Retail": "retail-flex-programs",
        "Transportation": "transportation-flex-programs",
      } as Record<string, any>

      const shortTerm = context.shortTermCourses.map((x: ShortTermCourse) => ({
        title: x.FormattedValues.c9_course,
        value: [{
          slug: `https://www.autryportal.com/session/session-details/?id=${x.Id}`,
          text: x.Attributes.c9_coursedescription,
          external: true
        }],
        external: true
      }))
      const flex = context.flexPrograms.map((x: ShortTermCourse) => ({
        title: x.FormattedValues.c9_course,
        value: [{
          slug: `/programs/${flexMap[x.FormattedValues.c9_cluster]}`,
          text: x.Attributes.c9_coursedescription
        }],
      }))
      const safety = context.safetySkills.map((x: ShortTermCourse) => ({
        title: x.FormattedValues.c9_course,
        value: [{
          slug: `https://www.autryportal.com/session/session-details/?id=${x.Id}`,
          text: x.Attributes.c9_coursedescription,
          external: true
        }],
      }))
      const allCourses = [ ...programs, ...shortTerm, ...flex, ...safety ]
      var flags: any = {}
      var uniqueCourses = allCourses.filter((entry) => {
          if (!flags[entry.title] && entry.title.toLowerCase().includes(search.toLowerCase())) {
            flags[entry.title] = true
            return true
          }
          return false
      });
      setResults(uniqueCourses)
    }
  }, [search, context, searchToggle, programs])

  const trim = (x: string) => {
    if (x && x.length > 150) {
      return `${x.substring(0, 150).replace(/\^.*/, "")} . . .`
    }
    return x.replace(/\^.*/, "")
  }

  const disabled = context?.indexingLoading ||
    (searchToggle === 'Course' && (context?.shortTermLoading || context?.flexLoading))

  const searchValue = searchToggle === 'Course'
    && context?.shortTermLoading && context?.flexLoading
    ? 'Loading...'
    : search


  const toLink = (value: any, key: any, includeTitle: boolean, title: string) => {
    if (value.external) {
      return (
        <a
          href={value.slug}
          key={key}
          onClick={() => {
            setResults([]);
            setSearch('')
            if (setMenuOpen) {
              setMenuOpen(false)
            }
          }}>
              {includeTitle && <h5>{parse(title)}</h5>}
      <p>{parse(trim(value.text))}</p>
        </a>
      )
    }
    return (
      <Link
      key={key}
      to={trimLink(value.slug)}
      onClick={() => {
        setResults([]);
        setSearch('');
        if (setMenuOpen) {
          setMenuOpen(false);
        }
      }}
    >
      {includeTitle && <h5>{parse(title)}</h5>}
      <p>{parse(trim(value.text))}</p>
    </Link>
    )
  }

  return (
    <div className={styles.siteSearch}>
      <input
        className={styles.searchBar}
        onChange={e => setSearch(e.target.value)}
        value={searchValue}
        disabled={disabled}
        placeholder={disabled ? 'Loading...' : ''} />
      <div className={styles.results + ` ${results.length > 0 ? styles.show : ''}`}>
         <button className={styles.close} onClick={() => { setResults([]); setSearch('')}}>x</button>
         <div className={styles.overlay} />
        {results.map((result: Result, i: number) => {
      let titleDisplayed = false;
      return (
        <div key={i} className={styles.result}>
          {result.value.map((value, idx) => {
            const includeTitle = !titleDisplayed;
            if (includeTitle) {
              titleDisplayed = true;
            }
            return toLink(value, idx, includeTitle, result.title);
          })}
        </div>
      );
    })}
      </div>
    </div>
  )
}