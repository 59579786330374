import React from 'react'
import { Blocks } from ".."

import styles from './main.module.scss'
import './styles/index.scss'

export type PageBlock = Record<string, any> & {
  blockName: string
  flexible_content_link_text?: string
}

export type PageBlockProps = {
  block: PageBlock
}

export default ({ children, scroll }: any) => {
  const { pageblocks } = children;
  return (
  <main id="main" className={styles.main}>
    {pageblocks && pageblocks.map((block: PageBlock, i: number) => {
      if (block.blockName && block.blockName !== 'core/paragraph') {
        const Block: React.FC = Blocks[block.blockName]
        return <Block key={i} {...{ block, scroll }} />
      }
    })}
    {/* <div className={styles.development}>
      <p className={styles.message}>Content under development.</p>
    </div> */}
  </main>
  )
}
