import React, { useEffect, useRef, useState, useContext } from "react"
import styles from "./ctaChangerFunnel.module.scss"
import SlantedBorder from "../slantedBorder"
import { Link, NavLink, useHistory } from "react-router-dom"
import { Context } from '../../../context'

type CTAProps = {
  block: Record<string, any>
  scroll: number
}

type Page = {
  label: string
  link: string
}

export default ({ block, scroll }: CTAProps) => {
  const [show, setShow] = useState<boolean>(false)
  const context = useContext(Context);
  const ref = useRef<HTMLDivElement>(null)

  const pages = block?.attrs?.pages?.filter((x:Page) => {
    const search = new URLSearchParams(window?.location?.search ?? '')
    if (search.get('path') === 'hs') {
      const lc = x.label.toLowerCase()
      return !lc.includes('short term') && !lc.includes('flex') && !lc.includes('short-term')
    } else {
      return true;
    }
  })

  const [timer, setTimer] = useState<any>()

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    return (() => clearTimeout(timer))
  }, [timer])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const scrollDown = () => {
    window.scrollTo({ top: window.innerHeight * .85, behavior: 'smooth' })
  }

  const scrollDownAfterLoad = () => {
    setTimer(setTimeout(() => {
      scrollDown()
    }, 1000))
  }
  return (
    <section className={styles.cta + ` ${show ? styles.show : ''}`} ref={ref}>
      <div
        role='img'
        aria-label={block.attrs.background_image_alt_text}
        className={styles.backgroundImage}
        style={{ backgroundImage: `url(${block.attrs.background_image_url})` }} />
      <div className={styles.overlay} />
      <h2
        className={styles.heading + ` ${!block.attrs.body ? styles.big : ''}`}>
        {block.attrs.heading}
      </h2>
      {block.attrs.body &&
      <p className={styles.body}>{block.attrs.body}</p>}
      {block.attrs.label &&
      <>
        {block.attrs.cta_link ? (
          <>
            {block.attrs.cta_link.startsWith("/") &&
            <Link className={styles.button} to={block.attrs.cta_link}>
              <p className={styles.buttonLabel}>{block.attrs.label}</p>
              <div className={styles.arrow} />
            </Link>}
            {!block.attrs.cta_link.startsWith("/") &&
            <a className={styles.button} href={block.attrs.cta_link} target="_blank" rel="noreferrer">
              <p className={styles.buttonLabel}>{block.attrs.label}</p>
              <div className={styles.arrow} />
            </a>}
          </>
        ) :
        <button className={styles.button} onClick={scrollDown}>
          <p className={styles.buttonLabel}>{block.attrs.label}</p>
          <div className={styles.arrow} />
        </button>}
        
      </>}
      {block.attrs.large_text &&
        <div
          className={styles.largeText}
          style={{
            transform: `translateX(${0 - scroll * .1}vw)`
          }}
        >{block.attrs.large_text}</div>
      }
      <div
        className={styles.slantedBorder}
        style={{
          bottom: '-2.5vw',
          top: 'unset',
          backgroundColor: block.attrs.funnel_color,
          transform: `skew(-3deg, -2.5deg)`
        }}
      />
      <div
        className={styles.changer}
        
        >
        <h4 className={styles.changerHeading}>{block.attrs.page_changer_heading}</h4>
        <div className={styles.pageLinks}>
          {pages && pages.map((page: Page, i: number) => (
            <NavLink
              key={i}
              className={styles.changerPage}
              activeClassName={styles.active}
              onClick={scrollDownAfterLoad}
              to={page.link}>
              {page.label}
            </NavLink>
          ))}
        </div>
      </div>
      
    </section>
  )
}