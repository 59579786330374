import apiFetch from "@wordpress/api-fetch";
import parse from "html-react-parser";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getPath } from "../../../utilities/getPath";
import SlantedBorder from "../slantedBorder";
import styles from "./programs.module.scss";
import TextScroller from "./textScroller";

type ProgramsProps = {
  block: Record<string, any>;
  scroll: number;
};

export type Program = {
  text: string;
  page_link: string;
  name: string;
};

type ProgramType =
  | "Funnels"
  | "Full-Time Programs"
  | "Short-Term Courses"
  | "Flex Programs"
  | "Partnering for Success";

export default ({ block, scroll }: ProgramsProps) => {
  const programTypes = block.attrs.program_types;
  const [programs, setPrograms] = useState<any>([]);
  const [fullTimePrograms, setFullTimePrograms] = useState<any>([]);
  const [funnels, setFunnels] = useState<any>([]);
  const [direction, setDirection] = useState<"up" | "down" | "unset">("unset");
  const [pause, setPause] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState<ProgramType>(programTypes[0] ?? "");

  const colorScheme = block.attrs.color_scheme;

  const section = useRef<HTMLDivElement>(null);

  useEffect(() => {
    apiFetch({ path: `${getPath()}/wp-json/wp/v2/programs?per_page=100` }).then(
      (res) => setPrograms(res)
    );
    apiFetch({ path: `${getPath()}/wp-json/wp/v2/funnels?per_page=100` }).then(
      (res) => setFunnels(res)
    );
    apiFetch({
      path: `${getPath()}/wp-json/wp/v2/fulltimeprograms?per_page=100`,
    }).then((res) => setFullTimePrograms(res));

    setTimeout(() => {
      if (section.current && section.current.getBoundingClientRect().top < 200)
        setShow(true);
    }, 50);
    setCurrent(programTypes[0] ?? "");
  }, [block]);

  useEffect(() => {
    if (scroll && section.current) {
      if (
        section.current.getBoundingClientRect().top <
          window.innerHeight * 0.5 &&
        section.current.getBoundingClientRect().bottom >
          window.innerHeight * 0.25
      ) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  }, [scroll]);

  const isShortTerm = (x: any) =>
    (parse(x.title.rendered) as string).toLowerCase().includes("short-term");

  const isFlex = (x: any) =>
    (parse(x.title.rendered) as string).toLowerCase().includes("flex");

  const shortTerm = programs
    .filter((x: any) => isShortTerm(x))
    .map((x: any) => ({
      text: parse(x.title.rendered),
      link: `/programs/${x.slug}`,
    }));

  const fullTime = fullTimePrograms.map((x: any) => ({
    text: x.name,
    link: `/programs/${x.page_link?.post_name}`,
  }));

  const flex = programs
    .filter((x: any) => isFlex(x))
    .map((x: any) => ({
      text: parse(x.title.rendered),
      link: `/programs/${x.slug}`,
    }));

  const funnelItems = funnels.map((x: any) => ({
    text: x.name,
    link: `/programs/${x.pagelink?.post_name}`,
  }));

  const contentMap = {
    Funnels: funnelItems ?? [],
    "Full-Time Programs": fullTime ?? [],
    "Short-Term Courses": shortTerm ?? [],
    "Flex Programs": flex ?? [],
    "Partnering for Success": [],
  };

  return (
    <section
      className={
        styles.programs +
        ` ${show ? styles.show : ""}` +
        ` ${colorScheme ? styles.light : ""}`
      }
      ref={section}
    >
      <div className={styles.left}>
        {block.attrs.heading && (
          <h2 className={styles.heading}>{block.attrs.heading}</h2>
        )}
        {programTypes &&
          programTypes.length > 1 &&
          programTypes.map((type: ProgramType, i: number) => (
            <button
              key={i}
              className={
                styles.programType + ` ${current === type ? styles.active : ""}`
              }
              onClick={() => setCurrent(type)}
              aria-label={`Show ${type}`}
            >
              {type}
            </button>
          ))}
        {programTypes && programTypes.length === 1 && (
          <h2 className={styles.programTypeSingle}>{programTypes[0]}</h2>
        )}
        {block.attrs.link?.startsWith("/") && (
          <Link to={block.attrs.link} className={styles.bottomText}>
            {block.attrs.bottom_text}
          </Link>
        )}
        {!block.attrs.link?.startsWith("/") && (
          <a
            href={block.attrs.link}
            target="_blank"
            rel="noreferrer"
            className={styles.bottomText}
          >
            {block.attrs.bottom_text}
          </a>
        )}
      </div>
      <div
        className={styles.right}
        onMouseEnter={() => {
          if (window.innerWidth > 500) setPause(true);
        }}
        onMouseLeave={() => setPause(false)}
      >
        <div className={styles.topOverlay} />
        <div
          className={styles.topArrow}
          onClick={() => setDirection("up")}
          role="Arrow Up"
        />
        {contentMap[current] && (
          <TextScroller
            {...{
              setDirection,
              direction,
              programs: contentMap[current],
              pause,
            }}
          />
        )}
        <div className={styles.bottomOverlay} />
        <div
          className={styles.bottomArrow}
          onClick={() => setDirection("down")}
          role="Arrow Down"
        />
      </div>
      {block.attrs.has_slanted_border && block.attrs.slanted_border && (
        <SlantedBorder {...JSON.parse(block.attrs.slanted_border)} />
      )}
    </section>
  );
};
