import React, { useEffect, useRef, useState } from "react";
import { Blocks } from "../..";
import { PageBlock, PageBlockProps } from "../../main/main";
import SlantedBorder from "../slantedBorder";
import styles from "./flexibleContent.module.scss";

type FlexibleContentProps = {
  block: Record<string, any>;
  scroll: number;
};

type FlexibleContentSection = {
  title: string;
  innerBlocks: any;
  attrs: Record<string, any>;
};

type FlexibleContentLink = {
  title: string;
  attrs: Record<string, any>;
  innerBlocks: any;
};

export default ({ block, scroll }: FlexibleContentProps) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const [stick, setStick] = useState<boolean>(false);
  const [top, setTop] = useState<string>("unset");

  useEffect(() => {
    setTimeout(() => {
      if (
        ref.current &&
        (ref.current.getBoundingClientRect().top < 200 ||
          ref.current.getBoundingClientRect().bottom < window.innerHeight)
      )
        setShow(true);
    }, 50);
  }, []);

  useEffect(() => {
    if (scroll && ref.current) {
      const next = ref.current?.nextElementSibling;
      if (
        ref.current.getBoundingClientRect().top < window.innerHeight * 0.35 &&
        ref.current.getBoundingClientRect().bottom > window.innerHeight * 0.5
      ) {
        setShow(true);
      }
      if (
        next &&
        next.getBoundingClientRect().top <= (window.innerWidth / 100) * 33.9 &&
        window.innerWidth <= 500
      ) {
        setTop(
          `${
            next.getBoundingClientRect().top - (window.innerWidth / 100) * 10.9
          }px`
        );
      } else if (
        ref.current.getBoundingClientRect().top <=
          (window.innerWidth / 100) * 10.9 &&
        window.innerWidth <= 500
      ) {
        setStick(true);
        setTop("23vw");
      } else if (
        ref.current.getBoundingClientRect().top >
        (window.innerWidth / 100) * 12
      ) {
        setStick(false);
        setTop("unset");
      }
    }
  }, [scroll]);

  const getFirstBlocks = (index: number) => {
    const section = block.innerBlocks[index];
    const defaultLink = section.innerBlocks[0];
    const defaultBlocks = defaultLink.attrs.has_nested_links
      ? defaultLink.innerBlocks[0].innerBlocks
      : defaultLink.innerBlocks;
    return defaultBlocks;
  };

  const getDefaultMenuLink = (index: number) => {
    const section = block.innerBlocks[index];
    const defaultLink = section.innerBlocks[0];
    const title = defaultLink.attrs.has_nested_links
      ? defaultLink.innerBlocks[0].attrs.title
      : defaultLink.attrs.title;
    return title;
  };

  const [currentBlocks, setCurrentBlocks] = useState<any>(getFirstBlocks(0));
  const [currentSection, setCurrentSection] = useState<number>(0);
  const [currentLink, setCurrentLink] = useState<number>(0);
  const [currentSubLink, setCurrentSubLink] = useState<number>(0);
  const [currentSubSubLink, setCurrentSubSubLink] = useState<number>(0);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const [currentMobileLink, setCurrentMobileLink] = useState(
    getDefaultMenuLink(0)
  );

  const getPosition = () => {
    if (window.innerWidth > 500) {
      return "relative";
    }
    return stick ? "fixed" : "absolute";
  };

  const scrollUp = () => {
    const sectionElement = ref.current;
    if (sectionElement) {
      const sectionOffset =
        sectionElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: sectionOffset - 160,
        behavior: "smooth",
      });
    }
  };
  return (
    <section
      className={styles.flexibleContent + ` ${show ? styles.show : ""}`}
      ref={ref}
    >
      <h2 className={styles.title}>{block.attrs.title}</h2>
      <div className={styles.body}>
        <div
          className={styles.sidebar + ` ${menuOpen ? styles.open : ""}`}
          style={{
            position: getPosition(),
            top: top,
          }}
        >
          <div className={styles.stick}>
            <button
              className={styles.mobileMenu}
              onClick={() => {
                setMenuOpen(true);
                scrollUp();
              }}
            >
              {currentMobileLink}
              <div className={styles.chevron} />
            </button>
            {block.innerBlocks &&
              block.innerBlocks.map(
                (section: FlexibleContentSection, sectionIndex: number) => (
                  <div
                    className={styles.section}
                    key={sectionIndex}
                    style={{
                      transitionDelay: `.${sectionIndex + 2}s`,
                    }}
                  >
                    <button
                      className={
                        styles.heading +
                        ` ${
                          currentSection === sectionIndex ? styles.active : ""
                        }`
                      }
                      onClick={() => {
                        setCurrentBlocks(getFirstBlocks(sectionIndex));
                        setCurrentSection(sectionIndex);
                        setCurrentMobileLink(getDefaultMenuLink(sectionIndex));
                        scrollUp();
                        setTimeout(() => {
                          setCurrentLink(0);
                          setCurrentSubLink(0);
                        }, 500);
                        if (!section.innerBlocks[0]?.attrs.title) {
                          setMenuOpen(false);
                          setCurrentMobileLink(section.attrs.title);
                        }
                      }}
                      aria-label={`Reset ${section.attrs.title}`}
                    >
                      {section.attrs.title}
                    </button>
                    <ul
                      className={styles.links}
                      style={{
                        maxHeight:
                          currentSection === sectionIndex ? "200vh" : "0",
                      }}
                    >
                      {section.innerBlocks &&
                        section.innerBlocks.map(
                          (link: FlexibleContentLink, linkIndex: number) => (
                            <li key={linkIndex}>
                              {!link.attrs.has_nested_links &&
                                link.attrs.title && (
                                  <button
                                    className={
                                      styles.link +
                                      ` ${
                                        currentLink === linkIndex
                                          ? styles.active
                                          : ""
                                      }`
                                    }
                                    onClick={() => {
                                      setCurrentBlocks(link.innerBlocks);
                                      setCurrentLink(linkIndex);
                                      setMenuOpen(false);
                                      setCurrentMobileLink(link.attrs.title);
                                      scrollUp();
                                    }}
                                    aria-label={`View ${link.attrs.title}`}
                                  >
                                    {link.attrs.title}
                                  </button>
                                )}
                              {link.attrs.has_nested_links && (
                                <button
                                  className={
                                    styles.link +
                                    ` ${
                                      currentLink === linkIndex
                                        ? styles.active
                                        : ""
                                    }`
                                  }
                                  onClick={() => {
                                    setCurrentLink(linkIndex);
                                    setTimeout(() => setCurrentSubLink(0), 500);
                                    setCurrentBlocks(
                                      link.innerBlocks[0].innerBlocks
                                    );
                                    scrollUp();
                                    setCurrentMobileLink(
                                      link.innerBlocks[0].attrs.title
                                    );
                                  }}
                                  aria-label={`View ${link.attrs.title}`}
                                >
                                  {link.attrs.title}
                                </button>
                              )}
                              <ul
                                className={styles.subLinks}
                                style={{
                                  maxHeight:
                                    currentLink === linkIndex ? "200vh" : "0",
                                }}
                              >
                                {link.attrs.has_nested_links &&
                                  link.innerBlocks &&
                                  link.innerBlocks.map(
                                    (
                                      subLink: FlexibleContentLink,
                                      subLinkIndex: number
                                    ) => (
                                      <li key={subLinkIndex}>
                                        {!subLink.attrs.has_nested_links &&
                                          subLink.attrs.title && (
                                            <button
                                              className={
                                                styles.subLink +
                                                ` ${
                                                  currentSubLink ===
                                                  subLinkIndex
                                                    ? styles.active
                                                    : ""
                                                }`
                                              }
                                              onClick={() => {
                                                setCurrentSubLink(subLinkIndex);
                                                setMenuOpen(false);
                                                setCurrentBlocks(
                                                  subLink.innerBlocks
                                                );
                                                setCurrentMobileLink(
                                                  subLink.attrs.title
                                                );
                                                scrollUp();
                                              }}
                                              aria-label={`View ${subLink.attrs.title}`}
                                            >
                                              {subLink.attrs.title}
                                            </button>
                                          )}
                                        {subLink.attrs.has_nested_links && (
                                          <button
                                            className={
                                              styles.subLink +
                                              ` ${
                                                currentSubLink === subLinkIndex
                                                  ? styles.active
                                                  : ""
                                              }`
                                            }
                                            onClick={() => {
                                              setCurrentSubLink(subLinkIndex);
                                              setTimeout(
                                                () => setCurrentSubSubLink(0),
                                                500
                                              );
                                              setCurrentBlocks(
                                                subLink.innerBlocks[0]
                                                  .innerBlocks
                                              );
                                              setCurrentMobileLink(
                                                subLink.innerBlocks[0].attrs
                                                  .title
                                              );
                                              scrollUp();
                                            }}
                                            aria-label={`View ${subLink.attrs.title}`}
                                          >
                                            {subLink.attrs.title}
                                          </button>
                                        )}
                                        <ul
                                          className={styles.subLinks}
                                          style={{
                                            maxHeight:
                                              currentLink === linkIndex
                                                ? "200vh"
                                                : "0",
                                          }}
                                        >
                                          {subLink.attrs.has_nested_links &&
                                            subLink.innerBlocks &&
                                            subLink.innerBlocks.map(
                                              (
                                                subSubLink: FlexibleContentLink,
                                                subSubLinkIndex: number
                                              ) => (
                                                <li key={subSubLinkIndex}>
                                                  {!subSubLink.attrs
                                                    .has_nested_links &&
                                                    subSubLink.attrs.title && (
                                                      <button
                                                        className={
                                                          styles.subSubLink +
                                                          ` ${
                                                            currentSubSubLink ===
                                                              subSubLinkIndex &&
                                                            currentSubLink ===
                                                              subLinkIndex
                                                              ? styles.active
                                                              : ""
                                                          }`
                                                        }
                                                        onClick={() => {
                                                          setCurrentSubLink(
                                                            subLinkIndex
                                                          );
                                                          setTimeout(
                                                            () =>
                                                              setCurrentSubSubLink(
                                                                subSubLinkIndex
                                                              ),
                                                            500
                                                          );
                                                          setCurrentSubSubLink(
                                                            subSubLinkIndex
                                                          );
                                                          setMenuOpen(false);
                                                          setCurrentBlocks(
                                                            subSubLink.innerBlocks
                                                          );
                                                          setCurrentMobileLink(
                                                            subSubLink.attrs
                                                              .title
                                                          );
                                                          scrollUp();
                                                        }}
                                                        aria-label={`View ${subSubLink.attrs.title}`}
                                                      >
                                                        {subSubLink.attrs.title}
                                                      </button>
                                                    )}
                                                </li>
                                              )
                                            )}
                                        </ul>
                                      </li>
                                    )
                                  )}
                              </ul>
                            </li>
                          )
                        )}
                    </ul>
                  </div>
                )
              )}
          </div>
        </div>
        <div className={styles.content}>
          {currentBlocks &&
            currentBlocks.map((block: PageBlock, i: number) => {
              if (block.blockName && block.blockName !== "core/paragraph") {
                const Block: React.FC<PageBlockProps> = Blocks[block.blockName];
                return Block ? <Block key={i} {...{ block, scroll }} /> : null;
              }
            })}
        </div>
      </div>
      {block.attrs.has_slanted_border && block.attrs.slanted_border && (
        <SlantedBorder {...JSON.parse(block.attrs.slanted_border)} />
      )}
    </section>
  );
};
