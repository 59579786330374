import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Context } from '../../../context'
import styles from './funnelGrid.module.scss'

type Funnel = {
  name: string
  color: string
  pagelink: { post_name: string }
  image: string
}

type GridProps = {
  block: Record<string, any>
  scroll: number
}

export default ({ block, scroll }: GridProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const context = useContext(Context)
  const funnels = context.funnels

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [context && context.shortTermCourses])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])
  return (
    <section className={styles.funnelGrid + ` ${show ? styles.show : ''}`} ref={ref}>
      <h4 className={styles.heading}>{block.attrs.heading}</h4>
      <div className={styles.items}>
        {funnels && funnels.sort((a: Funnel,b: Funnel) => b.name > a.name ? -1 : 1).map((funnel: Funnel, i: number) => (
          <Link key={i}
            to={funnel?.pagelink?.post_name ? `/programs/${funnel?.pagelink?.post_name}` : ""}
            className={styles.funnel}
            style={{
              transitionDelay: `${.2 + i * .2}s`
            }}>
            <img src={funnel.image} alt={funnel.name} />
            <div className={styles.nameBackground} style={{
                backgroundColor: funnel.color
              }}/>
            <span className={styles.name}>{funnel.name}</span>
          </Link>
        ))}
      </div>
    </section>
  )
}