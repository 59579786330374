import React from 'react'
import { Link } from 'react-router-dom'
import { MainLink } from '../../header'

const styles = require('./logo.module.scss')

type props = {
  logo: string
  setMega: React.Dispatch<MainLink | null>
  setMenuOpen: React.Dispatch<boolean>
}

export default ({ logo, setMega, setMenuOpen }: props) => {
  return <Link
    className={styles.logo}
    style={{ backgroundImage: `url(${logo})` }} to='/'
    onClick={() => { setMega(null); setMenuOpen(false) }}
    />
}
