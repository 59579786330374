import React from 'react'
import styles from './toggleField.module.scss'

type ToggleFieldProps = {
  setValues: React.Dispatch<any>
  values: any
  name: string
  label: string
  required: boolean
  option_one_label?: string
  option_one_value?: string
  option_two_label?: string
  option_two_value?: string
}

export default ({
  name, 
  label,
  values,
  required,
  setValues,
  option_one_label,
  option_one_value,
  option_two_label,
  option_two_value }: ToggleFieldProps) => {
  
  
  return (
    <div
      className={styles.toggleField}>
      <label htmlFor={name} tabIndex={0}>
        <span className={styles.label}>{label}</span>
        <span
          className={styles.optionOne + 
            ` ${values[name] === option_one_value ? styles.active : ''}`}>
          {option_one_label}
        </span>
        <span className={styles.toggle +
          ` ${values[name] === option_two_value ? styles.active : ''}`}></span>
        <span
          className={styles.optionTwo + 
            ` ${values[name] === option_two_value ? styles.active : ''}`}>
          {option_two_label}
        </span>
      </label>
      <input
        id={name}
        name={name}
        type='checkbox'
        checked={values[name] === option_two_value ? true : false}
        onChange={() => setValues({
          ...values,
          [name]:  values[name] === option_two_value
            ? option_one_value : option_two_value }) } />
    </div>
  )
}