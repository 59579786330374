import React, { useEffect, useState, useRef } from "react";
import styles from "./jobs.module.scss";
import moment from "moment";
import { getPath } from "../../../utilities/getPath";

type TableProps = {
  block: Record<string, any>;
  scroll: number;
};

export type Job = {
  job_title: string;
  job_type: string;
  location: string;
  date: string;
  url: string;
  email: string;
  id: number;
  post_status: string;
};

type Column = "Job Title" | "Posting Date" | "Type" | "Location";

export default ({ block, scroll }: TableProps) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState<string>("");
  const [jobs, setJobs] = useState<Job[]>([]);
  const [applyEmail, setApplyEmail] = useState("Apply Now");
  const [displayEmail, setDisplayEmail] = useState(false);

  const columns: Column[] = ["Job Title", "Posting Date", "Type", "Location"];

  const keyMap = {
    "Job Title": (x: Job) => x.job_title,
    "Posting Date": (x: Job) => moment(x.date).format("MM/DD/YYYY"),
    Type: (x: Job) => x.job_type,
    Location: (x: Job) => x.location,
  };

  const showEmail = () => {
    setApplyEmail("To apply, please email:");
    setDisplayEmail(true);
  };

  const getJobs = () => {
    window
      .fetch(`${getPath()}/wp-json/api/v2/get-jobs`, { method: "get" })
      .then(async (res) => {
        if (res.status === 200) {
          const response = await res.json();
          if (response) {
            const jobArray = response.map((x: any) => ({
              job_title: x?.fields?.job_title ? x.fields.job_title[0] : "",
              job_type: x?.fields?.job_type ? x.fields.job_type[0] : "",
              location: x?.fields?.location ? x.fields.location[0] : "",
              date: x?.job?.post_date ?? "",
              url: x?.fields?.url ? x.fields.url[0] : "",
              email: x?.fields?.email ? x.fields.email[0] : "",
              id: x?.job?.ID ?? "",
              post_status: x?.job?.post_status ?? "",
            }));
            setJobs(jobArray as Job[]);
          }
        }
      });
  };
  useEffect(() => {
    getJobs();

    setTimeout(() => {
      if (
        ref.current &&
        (ref.current.getBoundingClientRect().top < 200 ||
          ref.current.getBoundingClientRect().bottom < window.innerHeight)
      )
        setShow(true);
    }, 50);
  }, []);

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < window.innerHeight * 0.5) {
        setShow(true);
      }
    }
  }, [scroll]);

  const publishedJobs = jobs.filter((x) => x.post_status === "publish");

  const filteredJobs = search
    ? publishedJobs.filter((job: Job) => {
        if (columns) {
          let include = false;
          columns.forEach((column: Column) => {
            if (
              keyMap[column](job).toLowerCase().includes(search.toLowerCase())
            ) {
              include = true;
            }
          });
          return include;
        }
      })
    : publishedJobs;

  return (
    <section className={styles.table + ` ${show ? styles.show : ""}`} ref={ref}>
      <div className={styles.topRow}>
        <h3 className={styles.heading}>{block.attrs.heading}</h3>
        {block.attrs.subheading && <p>{block.attrs.subheading}</p>}
        <input
          className={styles.search}
          placeholder={block.attrs.search_placeholder}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.titleRow}>
          {columns.map((column, i: number) => (
            <div key={column} className={styles.title}>
              {column}
            </div>
          ))}
          <div className={styles.title}></div>
        </div>
        {filteredJobs &&
          filteredJobs.map((job: Job, jobIndex: number) => (
            <div
              key={jobIndex}
              className={styles.dataRow}
              style={{
                transitionDelay: `.${jobIndex * 2}s`,
              }}
            >
              {columns &&
                columns.map((column, columnIndex: number) => (
                  <div key={columnIndex} className={styles.rowItem}>
                    <div>{keyMap[column](job)}</div>
                  </div>
                ))}
              <div className={styles.rowItem}>
                {!job.url ? (
                  <button
                    className={
                      displayEmail ? styles["applyEmail"] : styles["apply"]
                    }
                    onClick={showEmail}
                  >
                    {applyEmail}
                    {displayEmail ? <span> {job.email}</span> : null}
                  </button>
                ) : (
                  <a
                    className={styles.apply}
                    href={job.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply Now
                  </a>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className={styles.mobile}>
        {filteredJobs &&
          filteredJobs.map((job: Job, rowIndex: number) => (
            <div
              key={rowIndex}
              className={styles.dataRow}
              style={{
                transitionDelay: `.${rowIndex * 2}s`,
              }}
            >
              {columns &&
                columns.map((column, columnIndex: number) => (
                  <div key={columnIndex} className={styles.rowItem}>
                    <div className={styles.columnTitle}>{column}</div>
                    <div className={styles.columnValue}>
                      <div>{keyMap[column](job)}</div>
                    </div>
                  </div>
                ))}
              <div className={styles.rowItem}>
                <div className={styles.columnTitle}></div>
                <div className={styles.columnValue}>
                  {!job.url ? (
                    <button
                      className={
                        displayEmail ? styles["applyEmail"] : styles["apply"]
                      }
                      onClick={showEmail}
                    >
                      {applyEmail}
                      {displayEmail ? <span> {job.email}</span> : null}
                    </button>
                  ) : (
                    <a
                      className={styles.apply}
                      href={job.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Apply Now
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};
