import React, { useEffect, useRef, useState } from "react"
import styles from "./overview.module.scss"
import parse from 'html-react-parser'

type OverviewProps = {
  block: Record<string, any>
  scroll: number
}

export default ({ block, scroll }: OverviewProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
  }, [scroll])

  return (
    <section className={styles.overview + ` ${show ? styles.show : ''}`} ref={ref}>
      <h3 className={styles.title}>{block.attrs.title}</h3>
      <h4 className={styles.heading}>{block.attrs.heading}</h4>
      {block.attrs.body &&
      <p className={styles.body}>{parse(block.attrs.body)}</p>}
      {block.attrs.image_url &&
      <img className={styles.image} src={block.attrs.image_url} alt={block.attrs.alt_text} />}
    </section>
  )
}