import React from 'react'
import styles from './checkboxField.module.scss'

type CheckboxFieldProps = {
  setValues: React.Dispatch<any>
  values: any
  name: string
  label: string
}

export default ({ name, label, values, setValues }: CheckboxFieldProps) => {
  return (
    <div className={styles.checkboxField}>
      <input
        id={name}
        name={name}
        type='checkbox'
        tabIndex={0}
        checked={values[name]}
        onChange={() => setValues({...values, [name]: !values[name] }) } />
      <label htmlFor={name} tabIndex={0}>
        <span className={styles.box +
          ` ${values[name] ? styles.active : ''}`}>
        </span>
        <span>{label}</span>
      </label>
    </div>
  )
}