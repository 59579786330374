import React from "react"
import { Link } from "react-router-dom"
import styles from "./ogPicker.module.scss"

type OGPickerProps = {
  block: Record<string, any>
  scroll: number
}

type OGItem = {
  image_url: string
  text: string
  link: string
  alt_text: string
  external_link: boolean
}

export default ({ block }: OGPickerProps) => {
  const { items } = block?.attrs
  return (
    <section className={styles.ogPicker}>
      {items && items.map((item: OGItem) => {
        return (
          <div key={item.text} className={styles.ogItem}>
            {item.external_link ? (
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <div
                  className={styles.image}
                  role='img'
                  aria-label={item.alt_text}
                  style={{ backgroundImage: `url(${item.image_url})` }} />
                <div className={styles.overlay} />
                <span className={styles.text}>{item.text}</span>
              </a>
            ) :
            <Link to={item.link}>
              <div
                className={styles.image}
                role='img'
                aria-label={item.alt_text}
                style={{ backgroundImage: `url(${item.image_url})` }} />
              <div className={styles.overlay} />
              <span className={styles.text}>{item.text}</span>
            </Link>}
          </div>
        )
      })}
    </section>
  )
}