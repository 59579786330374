import React, { useEffect, useState, useRef } from 'react'
import styles from './form.module.scss'
import { CheckboxField, CheckboxListField, SelectField, TextField, ToggleField } from './components'

type FormProps = {
  block: Record<string, any>
  scroll: number
}

export type Option = {
  label: string
  value: string
}

type FieldType = "Select" |
  "Checkbox" |
  "Checkbox List" |
  "Toggle" |
  "Text"

type Field = {
  name: string
  label: string
  width: string
  required: boolean
  options?: Option[]
  default?: Option
  option_one_label?: string
  option_one_value?: string
  option_two_label?: string
  option_two_value?: string
  type: FieldType
}

export default ({ block, scroll }: FormProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  const initialValues = {} as Record<string, any>
  const fields = block.attrs.fields

  if (fields) {
    fields.forEach((field: Field) => {
      if (field.type === 'Checkbox List') {
        initialValues[field.name] = []
      } else if (field.type === 'Checkbox') {
        initialValues[field.name] = false
      } else if (field.type === 'Toggle') {
        initialValues[field.name] = field.default ?? ''
      } else {
        initialValues[field.name] = ''
      }
    })
  }
  const [values, setValues] = useState<Record<string, any>>(initialValues)

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  return (
    <section className={styles.form + ` ${show ? styles.show : ''}`} ref={ref}>
      <form>
        <h3 className={styles.heading}>{block.attrs.heading}</h3>

        <div
          className={styles.fields}>
          {fields && fields.map((field: Field, i: number) => (
            <div
              key={i}
              className={styles.field}
              style={{
                width: `${field.width ?? 100}%`,
                transitionDelay: `${i * .2}s`
              }}>
              {field.type === "Text" &&
                <TextField {...{...field, values, setValues }} />
              }
              {field.type === "Select" &&
                <SelectField {...{...field, values, setValues }} />
              }
              {field.type === "Toggle" &&
                <ToggleField {...{...field, values, setValues }} />
              }
              {field.type === "Checkbox" &&
                <CheckboxField {...{...field, values, setValues }} />
              }
              {field.type === "Checkbox List" &&
                <CheckboxListField {...{...field, values, setValues }} />
              }
            </div>
          ))}
        </div>
        <div className={styles.ctaContainer}>
          <div className={styles.cta}>
            <p>{block.attrs.cta_text}</p>
            <div className={styles.arrowContainer}>
              <div className={styles.arrow} />
            </div>
          </div>
        </div>
      </form>
      
    </section>
  )
}