import React from 'react'
import { ContextProvider } from './context'
import { render } from 'react-dom'
import './index.css'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Archive, NotFound, Page, Single } from './templates'

render(
  <BrowserRouter>
    <ContextProvider>
      <Switch>
        <Route exact path='/' component={Page} />
        <Route path='/post/:slug' component={Single} />
        <Route path='/search/:term' component={Archive} />
        <Route path='/:slug' component={Page} />
        <Route component={NotFound} />
      </Switch>
    </ContextProvider>
  </BrowserRouter>, document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
