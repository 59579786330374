import apiFetch from "@wordpress/api-fetch";
import React, { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";
import { Context } from "../context";
import { getPath } from "../utilities/getPath";
import Footer from "./footer";
import Header from "./header";
import Main from "./main";

import "./global.scss";
import "./reset.scss";

export default ({ children, title }: any) => {
  const context = useContext(Context);
  const [siteInfo, setSiteInfo] = useState<any>({
    name: "",
  });
  const history = useHistory();
  history.listen(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    apiFetch({ path: `${getPath()}/wp-json` }).then((res) => {
      setSiteInfo(res);
      console.log(res);
    });
    if (typeof window !== "undefined") {
      const updateScroll = () => {
        context.setScroll(window.scrollY);
        if (window.scrollY > 15) context.setScrolled(true);
        else context.setScrolled(false);
      };

      updateScroll();
      context.setScroll(window.scrollY);
      window.addEventListener("scroll", updateScroll);

      return () => {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }, []);

  return (
    <div style={{ width: "100vw" }}>
      <Helmet>
        <title>
          {title !== "Home" ? `${title} | ` : ""}
          {siteInfo.name}
        </title>
      </Helmet>
      <a className="skipContent" href="#main">
        Skip to content
      </a>
      <Header />
      <Main scroll={context && context.scroll}>{children}</Main>
      <Footer scroll={context && context.scroll} />
    </div>
  );
};
