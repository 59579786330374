import React, { useState, useEffect } from "react"
import styles from "./slider.module.scss"
import { Filters } from "../.."

type Props = {
  filters: Filters
  setFilters: React.Dispatch<Filters>
}

export default (props: Props) => {
  const { filters, setFilters } = props
  const [colorOneWidth, setColorOneWidth] = useState(filters.capacity);
  const [sliderOneCurrent, setSliderOneCurrent] = useState(filters.capacity);
  
  const handleChangeOne = (e: any) => {
    setColorOneWidth(e.target.value);
    setSliderOneCurrent(e.target.value < 99 ? Math.ceil(+e.target.value) : Math.floor(+e.target.value));
    setFilters({...filters, capacity: +e.target.value})
  }

  const calculateLeft = () => {
    return -1.5 + ((sliderOneCurrent - 1) * .94)
  }

  useEffect(() => {
    setColorOneWidth(filters.capacity)
    setSliderOneCurrent(filters.capacity)
  }, [filters])

  return (
    <div className={styles.slideContainer}>
      <div
        className={styles.valueCircle}
        style={{
          left: `${calculateLeft()}%`
        }}
        >
          <div className={styles.valueContainer}>
            {sliderOneCurrent}+
          </div>
      </div>
      <div className={styles.colorContainer}>
        <div className={styles.color} style={{ width: `${colorOneWidth * .97 + 2}%` }}></div>
      </div>
      <label className={styles.label} htmlFor="capacity">Capacity</label>
      <input
        className={styles.slider}
        id="capacity"
        name="capacity"
        type="range"
        min="1"
        max="100"
        step="1"
        value={filters.capacity}
        onChange={handleChangeOne} />
    </div>
  ) 
}
