import React from 'react'
import styles from './callButton.module.scss'

type CallButtonProps = {
  icon: string
  number_display: string
  number_link: string
}
export default (props: CallButtonProps) => {
  return (
    <a className={styles.callButton} href={`tel:${props.number_link}`}>
      {props.icon &&
      <img src={props.icon} alt="" />}
      <span>{props.number_display}</span>
    </a>
  )
}