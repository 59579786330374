import React, { useEffect, useState, useRef } from 'react'
import styles from './jobs.module.scss'
import JobForm from "./jobForm"
import LoginForm from "./loginForm"
import CreateAccountForm from "./createAccountForm"
import ChangePasswordForm from "./changePasswordForm"
import ResetPasswordForm from "./resetPassword"
import EditJobForm from "./editJobForm"
import { getPath } from '../../../utilities/getPath'

type TableProps = {
  block: Record<string, any>
  scroll: number
}

export type Job = {
  job_title: string
  job_type: string
  location: string
  date: string
  url: string
  email: string
  id: number
  post_status: string
}

export type Form = "Job"
  | "Login"
  | "Create Account"
  | "Change Password"
  | "Reset Password"
  | "Edit Job"

type FormComponentProps = {
  block: Record<string, any>
  setForm: React.Dispatch<Form>
  setUser: React.Dispatch<any>
  user: any
  form: Form
  jobs: Job[]
  getJobs: () => void
}

const FormComponent: React.FC<FormComponentProps> = (props) => {
  const { form } = props;

  if (form === "Job") {
    return <JobForm {...props} />
  }
  if (form === "Login") {
    return <LoginForm {...props} />
  }
  if (form === "Create Account") {
    return <CreateAccountForm {...props} />
  }
  if (form === "Change Password") {
    return <ChangePasswordForm {...props} />
  }
  if (form === "Reset Password") {
    return <ResetPasswordForm {...props} />
  }
  if (form === "Edit Job") {
    return <EditJobForm {...props} />
  }
  return null;
}

export default ({ block, scroll }: TableProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const [jobs, setJobs] = useState<Job[]>([])
  const [user, setUser] = useState<any>(null);
  const [form, setForm] = useState<Form>("Login");

  const getJobs = () => {
    window.fetch(`${getPath()}/wp-json/api/v2/get-jobs`, { method: 'get'}).then(async res => {
      if (res.status === 200) {
        const response = await res.json()
        if (response) {
          const jobArray = response.map((x: any) => ({
            job_title: x?.fields?.job_title ? x.fields.job_title[0] : '',
            job_type: x?.fields?.job_type ? x.fields.job_type[0] : '',
            location: x?.fields?.location ? x.fields.location[0] : '',
            date: x?.job?.post_date ?? '',
            url: x?.fields?.url ? x.fields.url[0] : '',
            email: x?.fields?.email ? x.fields.email[0] : '',
            id: x?.job?.ID ?? '',
            post_status: x?.job?.post_status ?? '',
          }))
          setJobs(jobArray as Job[])
        }
      }
    })
  }
  useEffect(() => {
    getJobs()

    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
    window.fetch(`${getPath()}/wp-json/api/v2/get-job-post-user`, {
      method: 'get',
    }).then(async (res: any) => {
      if (res.status === 200) {
        const response = await res.json();
        if (response.ID !== 0) {
          setUser(response);
          setForm("Job")
        }
      }
    })
  }, [form])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  return (
    <section className={styles.table + ` ${show ? styles.show : ''}`} ref={ref}>
      <div className={styles.topRow}>
        <h3 className={styles.heading}>{block.attrs.heading}</h3>
      </div>

      <FormComponent {...{ block, setForm, setUser, user, form, jobs, getJobs }} />
 
    </section>
  )
}