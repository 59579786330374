import React from 'react'
import { Option } from '../..'
import styles from './selectField.module.scss'

type SelectFieldProps = {
  setValues: React.Dispatch<any>
  values: any
  name: string
  label: string
  required: boolean
  options?: Option[]
}

export default ({ name, label, values, options, required, setValues }: SelectFieldProps) => {

  return (
    <div
      className={styles.select}>
      <label htmlFor={name}>{label}{required ? ' *' : ''}</label>
      <select
        id={name}
        name={name}
        value={values[name]}
        onChange={e => setValues({...values, [name]: e.target.value })}>
        <option value=''>{'<Select an option>'}</option>
        {options && options.map((option: Option) =>(
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className={styles.chevron} />
    </div>
  )
}