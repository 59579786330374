import apiFetch from '@wordpress/api-fetch'
import parse from "html-react-parser"
import moment, { Moment } from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import Loader from "react-loader-spinner"
import { Link } from 'react-router-dom'
import { getPath } from '../../../utilities/getPath'
import styles from './staff.module.scss'

type TableProps = {
  block: Record<string, any>
  scroll: number
}

export type Staff = {
  name: string
  phone: string
  staff_title: string
  staff_link: string
}

type Column = "Name"
  | "Title"
  | "Phone"

export default ({ block, scroll }: TableProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState<string>('')
  const [staff, setStaff] = useState<any>([])
  const [showAll, setShowAll] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(0)
  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)

    window.fetch(`${getPath()}/wp-json/api/v2/get-staff`, { method: 'get'}).then(async res => {
      if (res.status === 200) {
        const response = await res.json()
        if (response) {
          const staffArray = response.map((x: any) => ({
            staff_link: x?.fields?.staff_link ? x.fields.staff_link[0] : '',
            name: x?.fields?.name ? x.fields.name[0] : '',
            phone: x?.fields?.phone ? x.fields.phone[0] : '',
            staff_title: x?.fields?.staff_title ? x.fields.staff_title[0] : '',
          }))
          staffArray.sort((a: any, b: any) => {
            const splitFirst = a?.name?.split(/[ ]+/)[a.name.split(/[ ]+/)?.length - 1]
            const splitSecond = b?.name?.split(/[ ]+/)[b.name.split(/[ ]+/)?.length - 1]
            if (splitSecond > splitFirst) {
              return -1
            } else return 1
          })
          setStaff(staffArray)
        }
      }
    })
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const columns: Column[] = [
    "Name",
    "Title",
    "Phone",
  ];

  const keyMap = {
    "Name": (x: Staff) => x.name,
    "Title": (x: Staff) => x.staff_title,
    "Phone": (x: Staff) => x.phone,
  }

  const filteredStaff = search ? staff.filter((member: Staff) => {
    if (columns) {
      let include = false
      columns.forEach((column: Column) => {
        if (keyMap[column](member).toLowerCase().includes(search.toLowerCase())) {
          include = true
        }
      })
      return include
    }
  }) : staff

  const paginatedStaff = !showAll && filteredStaff ? filteredStaff.reduce((acc: any, val: any, i: number) => {
    const idx = Math.floor(i / 10)
    const page = acc[idx] || (acc[idx] = [])
    page.push(val)
    return acc
  }, []) : (filteredStaff ? [filteredStaff] : [])

  if (staff.length === 0) {
    return (
      <section className={styles.table + ` ${show ? styles.show : ''}`} ref={ref}>
        <div className={styles.topRow}>
          <h3 className={styles.heading}>Our Staff</h3>
        </div>
        <Loader type="TailSpin" color="#091C3D" height={50} width={50} />
      </section>
    )
  }

  const pageIndex = currentPage > (paginatedStaff.length - 1) ? (paginatedStaff.length - 1) : currentPage

  return (
    <section className={styles.table + ` ${show ? styles.show : ''}`} ref={ref}>
      <div className={styles.topRow}>
        <h3 className={styles.heading}>Our Staff</h3>
        <input
          className={styles.search}
          placeholder={block.attrs.search_placeholder}
          onChange={e => setSearch(e.target.value)}/>
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.titleRow}>
          {columns.map((column, i: number) => (
            <div key={column} className={styles.title
               + ` ${i === 1 ? styles.wide : ""}`
            }>{column}</div>
          ))}
        </div>
        {paginatedStaff.length > 0 && paginatedStaff[showAll ? 0 : pageIndex].map((member: Staff, staffIndex: number) => (
          <div
            key={staffIndex}
            className={styles.dataRow}
            style={{
              transitionDelay: `.${staffIndex * 2}s`
            }}
          >
            {columns &&
              <a
                href={member.staff_link}
                target="_blank"
                rel="noopener noreferrer"
                className={
                  styles.rowItem + ` ${styles.first}`
                }>
                <div>
                  {keyMap.Name(member)}
                </div>
              </a>
            }
            {columns && columns.slice(1).map((column, columnIndex: number) => (
              <div
                key={columnIndex}
                className={
                  styles.rowItem + ` ${columnIndex === 0 ? styles.wide : ""}`
                }>
                <div>
                  {keyMap[column](member)}
                </div>
              </div>
            ))}
          </div>
        ))}
        <div className={styles.pageButtons}>
          <button onClick={() => setShowAll(!showAll)}>
            {showAll ? 'Hide all' : 'Show all'}
          </button>
          {paginatedStaff.length > 1 && paginatedStaff.map((x: any, i: number) => (
            <button key={i} onClick={() => setCurrentPage(i)}>{i + 1}</button>)
          )}
        </div>
      </div>
      <div className={styles.mobile}>
        {paginatedStaff.length > 0 && paginatedStaff[showAll ? 0 : pageIndex].map((member: Staff, rowIndex: number) => (
          <div
            key={rowIndex}
            className={styles.dataRow}
            style={{
              transitionDelay: `.${rowIndex * 2}s`
            }}
          >
            {columns &&
              <a
                href={member.staff_link}
                target="_blank"
                rel="noopener noreferrer"
                className={
                  styles.rowItem + ` ${styles.first}`
                }>
                <div>
                  {keyMap.Name(member)}
                </div>
              </a>
            }
            {columns && columns.slice(1).map((column, columnIndex: number) => (
              <div
                key={columnIndex}
                className={
                  styles.rowItem + ` ${columnIndex === 1 ? styles.wide : ""}`
                }>
                <div className={styles.columnTitle}>{column}</div>
                <div className={styles.columnValue}>
                  <div>
                    {keyMap[column](member)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
        <div className={styles.pageButtons}>
          <button onClick={() => setShowAll(!showAll)}>
            {showAll ? 'Hide all' : 'Show all'}
          </button>
          {paginatedStaff.length > 1 && paginatedStaff.map((x: any, i: number) => (
            <button key={i} onClick={() => setCurrentPage(i)}>{i + 1}</button>)
          )}
        </div>
      </div>
    </section>
  )
}