import React from 'react'
import { Form } from '..'
import styles from './baseForm.module.scss'

export type TextField = {
  label: string
  name: string
}

type OtherButton = {
  label: string
  callback: () => void
}

type BaseFormProps = {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  formValues: Record<string, any>
  setFormValues: React.Dispatch<Record<string, any>>
  message: string
  fields: TextField[]
  setForm: React.Dispatch<Form>
  buttons: OtherButton[]
  submitLabel: string
}

export default ({
  handleSubmit,
  formValues,
  setFormValues,
  message,
  fields,
  buttons,
  submitLabel
}: BaseFormProps) => {

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.form}>
        {fields && fields.map((field: TextField, i: number) => (
          <div className={styles.textField} key={field.name}>
            <input
              name={field.name}
              value={formValues[field.name]}
              className={styles.textField}
              style={{ transitionDelay: `.${i * 2}s`}}
              placeholder={field.label}
              required
              type={field.name === "password" ? "password" : "text"}
              onChange={(e) => 
                setFormValues({ ...formValues, [field.name]: e.target.value})
              }
            />
          </div>
        ))}
        
        <div className={styles.formRow}>
          <div
            className={styles.ctaForm}>
            <button className={styles.cta}>
              <p className={styles.buttonLabel}>{submitLabel}</p>
              <div className={styles.arrow} />
            </button>
          </div>
          {buttons && buttons.map((button: OtherButton, i: number) => (
            <button
              key={i}
              type='button'
              className={styles.otherButton}
              onClick={button.callback}
            >
              {button.label}
            </button>
          ))}
        </div>
      </form>
      <p className={styles.formMessage}>{message}</p>
    </>
  )
}
