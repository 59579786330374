import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Context } from '../../../../context/context';
import { trimLink } from "../../../../utilities/trimLink";
import { MainLink, SubPage } from "../../header";
import styles from "./megamenu.module.scss";

type MegaMenuProps = {
  mega: MainLink | null
  setMega: React.Dispatch<MainLink | null>
}

type Funnel = {
  name: string
  color: string
  pagelink: { post_name: string }
}

export default ({ mega, setMega }: MegaMenuProps) => {
  const [currentPageGroup, setCurrentPageGroup] = useState<number | null>(null)
  const context = useContext(Context)
  const funnels = context.funnels

  

  useEffect(() => {
    setCurrentPageGroup(null)
  }, [mega])

  const hasActiveSubPage = (option: SubPage) => {
    const location = window.location.pathname
    let isActive = false
    console.log(option)
    if (option.is_page_group && option.pages) {
      option.pages.forEach(page => {
        if (location !== '/' && page.page.includes(location)) {
          isActive = true
        }
      })
    }

    return isActive
  }

  const handleClick = (index: number) => {
    if (currentPageGroup === index) {
      setCurrentPageGroup(null)
    } else {
      setCurrentPageGroup(index)
    }
  }

  const sortFunnels = (a: Funnel, b: Funnel) => a.name < b.name ? -1 : 1

  const isHs = mega?.label?.toLowerCase() === 'high school'

  return (
    <div className={styles.megamenu + ` ${mega ? styles.show : ''}`
      + ` ${!mega?.show_funnels ? styles.two : ''}`}>
      <button className={styles.close} onClick={() => setMega(null)}>x</button>
      <div className={styles.left + ` ${mega ? styles.show : ''}`}>
        <h2 className={styles.heading}>{mega?.label}</h2>
        {mega?.sub_pages && mega.sub_pages.map((option, optionIndex) => {
          return (
          <div key={option.label}>
            {option?.label !== "Catalog" ? (
               !option.is_page_group ?
                <NavLink
                  to={option?.page ? trimLink(option.page) : ""}
                  key={optionIndex}
                  className={styles.option}
                  activeClassName={styles.active}
                  onClick={() => setMega(null)}>
                  {option.label}
                </NavLink> :
                <div className={styles.pageGroup}>
                  <button
                    className={styles.option}
                    onClick={() => handleClick(optionIndex)}>
                    {option.label}
                  </button>
                  <div
                    className={styles.subOptions +
                    ` ${currentPageGroup === optionIndex || hasActiveSubPage(option) ?
                      styles.show : ''}`}
                  >
                    {option.pages && option.pages.map((page, subOptionIndex) => (
                      <NavLink
                        to={page?.page ? trimLink(page.page) : ''}
                        key={subOptionIndex}
                        className={styles.subOption +
                        ` ${currentPageGroup === optionIndex || hasActiveSubPage(option) ?
                          styles.show : ''}`}
                        activeClassName={styles.active}
                        onClick={() => setMega(null)}>
                        {page.label}
                      </NavLink>
                    ))}
                  </div>
                </div>
            ) : (
              <a className={styles.option} href={option?.url}>{option.label}</a>
            )
          }
          </div>
          )
        }
        )}
      </div>
      {mega?.show_funnels &&
      <div className={styles.middle + ` ${mega ? styles.show : ''}`}>
        {funnels && funnels.sort(sortFunnels).map((funnel: Funnel, i: number) => (
          <NavLink
            to={funnel?.pagelink?.post_name
              ? `/programs/${funnel?.pagelink?.post_name}${isHs ? '?path=hs' : ''}`
              : ''}
            onClick={() => setMega(null)}
            className={styles.item}
            key={i}>
            {funnel.name}
            <div
              className={styles.highlight}
              style={{
                backgroundColor: funnel.color
              }}
              />
          </NavLink>
        ))}
      </div>}
      {mega && mega.image && mega.text &&
      <div className={styles.right + ` ${mega ? styles.show : ''}`}>
        <img
          className={styles.image}
          src={mega.image} 
          alt={mega.text} />
        <div className={styles.overlay} />
        <h3 className={styles.text}>{mega.text}</h3>
      </div>}
    </div>
  )
}