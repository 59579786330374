import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import styles from "./stepsToApply.module.scss"

type StepsToApplyProps = {
  block: Record<string, any>
  scroll: number
}

export type Step = {
  text: string
  image_url: string
  alt_text: string
}

export default ({ block, scroll }: StepsToApplyProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [animate, setAnimate] = useState(false)
  const steps = block.attrs.steps

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setAnimate(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .50)) {
        setAnimate(true)
      }
    }
  }, [scroll])

  return (
  <section className={styles.stepsToApply} ref={ref}>
    <h3 className={styles.title}>{block.attrs.title}</h3>
    <div className={styles.steps}>
      <hr className={styles.line + ` ${animate ? styles.animate : ''}`} />
      {steps && steps.map((step: Step, i: number) => {
        return (
          <div
            key={i}
            className={styles.step + ` ${animate ? styles.animate : ''}`}
            style={{
              transitionDelay: `${i * .5}s`,
              zIndex: 3 - i
            }}>
              <div className={styles.graphics}>
                <img
                  src={step.image_url}
                  alt={step.alt_text}
                  style={{
                    transitionDelay: `${i * 1}s`
                  }}/>
                <div className={styles.number}>
                  <div className={styles.numContainer}>
                    <span>{i + 1}</span>
                  </div>
                </div>
              </div>
              <div
                className={styles.dashedBox + ` ${animate ? styles.hide : ''}`}
                style={{
                  transitionDelay: `${i * 1}s`
                }}
              />
              <div
                className={styles.solidBox + ` ${animate ? styles.show : ''}`}
                style={{
                  transitionDelay: `${i * 1}s`
                }}
              />
            <p
              className={styles.text}
              style={{
                transitionDelay: `${i * 1}s`
              }}>
              {step.text}
            </p>
          </div>
        )
      })}
      
    </div>
    {block.attrs.cta_text &&
    <div className={styles.ctaContainer}>
      {block.attrs.cta_link?.startsWith("/") &&
      <Link className={styles.cta} to={block.attrs.cta_link}>
        <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
      </Link>}
      {!block.attrs.cta_link?.startsWith("/") &&
      <a className={styles.cta} href={block.attrs.cta_link} target="_blank" rel="noopener noreferrer">
        <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
      </a>}
    </div>}
  </section>
  )
}