import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import SlantedBorder from "../slantedBorder"
import styles from './textVideoLinks.module.scss'

type GridProps = {
  block: Record<string, any>
  scroll: number
}

type Item = {
  text: string
  video_url: string
}

export default ({ block, scroll }: GridProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  const items = block.attrs.links

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const scrollDown = () => {
    window.scrollTo({ top: window.innerHeight * .85, behavior: 'smooth' })
  }

  return (
    <section className={styles.videos + ` ${show ? styles.show : ''}`} ref={ref}>
      <h4 className={styles.heading}>{block.attrs.heading}</h4>
      <h6 className={styles.subheading}>{block.attrs.subheading}</h6>
      <div className={styles.items}>
        {items && items.map((item: Item, i: number) => (
          <div className={styles.videoContainer} key={i}>
            <div className={styles.videoWrapper}>
              <iframe
                width="560"
                height="349"
                src={item.video_url}
                allowFullScreen>
              </iframe>
            </div>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
      {block.attrs.cta_text &&
      <div className={styles.ctaContainer}>
        {block.attrs.cta_link?.startsWith("/") &&
        <Link className={styles.cta} to={block.attrs.cta_link}>
          <p>{block.attrs.cta_text}</p>
          <div className={styles.arrowContainer}>
            <div className={styles.arrow} />
          </div>
        </Link>}
        {!block.attrs.cta_link &&
        <button className={styles.cta} onClick={scrollDown}>
          <p>{block.attrs.cta_text}</p>
          <div className={styles.arrowContainer}>
            <div className={styles.arrow} />
          </div>
        </button>}
        {block.attrs.cta_link && !block.attrs.cta_link?.startsWith("/") &&
        <a className={styles.cta} href={block.attrs.cta_link} target="_blank" rel="noopener noreferrer">
          <p>{block.attrs.cta_text}</p>
          <div className={styles.arrowContainer}>
            <div className={styles.arrow} />
          </div>
        </a>}
      </div>}
      {block.attrs.has_slanted_border && block.attrs.slanted_border &&
      <SlantedBorder {...JSON.parse(block.attrs.slanted_border)} />}
    </section>
  )
}