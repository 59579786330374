import React, { useEffect, useRef, useState } from "react"
import { useCookies } from 'react-cookie'
import TextScroller from "../horizonalTextScroller"
import SlantedBorder from "../slantedBorder"
import styles from "./hero.module.scss"

type HeroProps = {
  block: Record<string, any>
  scroll: number
}

type Images = {
  person: Image
  background: Image
}

type Image = {
  image_url: string
  alt_text: string
}

export default ({ block, scroll }: HeroProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const section = useRef<HTMLDivElement>(null)
  const [cookies, setCookie] = useCookies(['hero-images-index']);
  const descriptions = block.attrs.descriptions
  const images = block.attrs.images
  const default_text = block.attrs.default_text
  const [hide, setHide] = useState(false)
  const [currentRow, setCurrentRow] = useState<number | undefined>(1)
  const [currentImages] = useState<Images>(images[
    cookies['hero-images-index'] ? +cookies['hero-images-index'] : 0
  ])
  const [show, setShow] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setHide(true)
      setHide(false)
    }, 60000)

    if (cookies['hero-images-index']) {
      const index = +cookies['hero-images-index']
      if (index >= images.length - 1) {
        setCookie('hero-images-index', 0)
      } else {
        setCookie('hero-images-index', index + 1)
      }
    } else {
      setCookie('hero-images-index', 0)
    }

    setTimeout(() => {
      setShow(true)
    }, 50)

    return (() => clearInterval(interval))
  }, [])

  useEffect(() => {
    if (scroll && section.current) {
      if (section.current.getBoundingClientRect().top < (window.innerHeight * .5) &&
      section.current.getBoundingClientRect().bottom > (window.innerHeight * .25)) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
  }, [scroll])

  const attributes = descriptions.map((x: string) => ({ text: x }))
  
  return (
    <section className={styles.hero} ref={section}>
      <div
        className={styles.backgroundImage}
        role='img'
        aria-label={block.attrs.background_image_alt_text}
        style={{ backgroundImage: `url(${currentImages.background.image_url})` }} />
      <div className={styles.overlay} />
      {!hide &&
      <div className={styles.scrollers + ` ${show ? styles.show : ''}`} ref={ref}>
        <div className={styles.scrollContainer +
          ` ${currentRow ? styles.hide : ''}`}>
          <TextScroller
            direction='left'
            duration={400}
            index={1}
            {...{ attributes, styles, scroll, currentRow, setCurrentRow }}
            defaultText={default_text}
          />
        </div>
        <div className={styles.scrollContainer +
          ` ${currentRow ? styles.hide : ''}`}>
          <TextScroller
            direction='right'
            duration={480}
            index={2}
            {...{ attributes, styles, scroll, currentRow, setCurrentRow }}
            defaultText={default_text}
          />
        </div>
        <div className={styles.scrollContainer +
          ` ${currentRow ? styles.hide : ''}`}>
          <TextScroller
            direction='left'
            duration={640}
            index={3}
            {...{ attributes, styles, scroll, currentRow, setCurrentRow }}
            defaultText={default_text}
          />
        </div>
        <div className={styles.scrollContainer2 +
          ` ${currentRow ? styles.hide : ''}`}>
          <TextScroller
            direction='right'
            duration={640}
            index={4}
            {...{ attributes, styles, scroll, currentRow, setCurrentRow }}
            defaultText={default_text}
          />
        </div>
        <div className={styles.scrollContainer2 +
          ` ${currentRow ? styles.hide : ''}`}>
          <TextScroller
            direction='left'
            duration={520}
            index={5}
            {...{ attributes, styles, scroll, currentRow, setCurrentRow }}
            defaultText={default_text}
          />
        </div>
        <div className={styles.scrollContainer2 +
          ` ${currentRow ? styles.hide : ''}`}>
          <TextScroller
            direction='right'
            duration={800}
            index={6}
            {...{ attributes, styles, scroll, currentRow, setCurrentRow }}
            defaultText={default_text}
          />
        </div>
        {images &&
        <div
          className={styles.person + ` ${currentRow ? styles.show : ''}`}
          style={{
            backgroundImage: `url(${currentImages.person.image_url})`
          }}
          >
        </div>}
        <h1 className={styles.defaultText + ` ${currentRow ? styles.show : ''}`}>
          {default_text}
        </h1>
      </div>}
      {block.attrs.has_slanted_border && block.attrs.slanted_border &&
      <SlantedBorder {...JSON.parse(block.attrs.slanted_border)} />}
      {block.attrs.large_text &&
        <div className={styles.largeText}>{block.attrs.large_text}</div>
      }
    </section>
  )
}