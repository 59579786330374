import parse from 'html-react-parser'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import SlantedBorder from "../slantedBorder"
import styles from './twoColumnText.module.scss'

type TwoColumnTextProps = {
  block: Record<string, any>
  scroll: number
}

export default ({ block, scroll }: TwoColumnTextProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const colorScheme = block.attrs.color_scheme

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  const scrollDown = () => {
    window.scrollTo({ top: (window.innerHeight * .85) + window.scrollY, behavior: 'smooth' })
  }


  return (
    <section className={styles.twoColumnText + ` ${show ? styles.show : ''}` +
    ` ${colorScheme ? styles.light : ''}`} ref={ref}>
      <div className={styles.left}>
        <h3 className={styles.leftText}>
          {parse(block.attrs.column_one)}
        </h3>
      </div>
      <div className={styles.right}>
        <p className={styles.rightText}>
          {parse(block.attrs.column_two)}
        </p>
        {block.attrs.cta_text &&
        <div className={styles.ctaContainer}>
          {block.attrs.cta_link?.startsWith("/") &&
          <Link className={styles.cta} to={block.attrs.cta_link}>
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </Link>}
          {!block.attrs.cta_link &&
          <button className={styles.cta} onClick={scrollDown}>
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </button>}
          {block.attrs.cta_link && !block.attrs.cta_link?.startsWith("/") &&
          <a className={styles.cta} href={block.attrs.cta_link} target="_blank" rel="noopener noreferrer">
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </a>}
        </div>}
      </div>
      {block.attrs.has_slanted_border && block.attrs.slanted_border &&
      <SlantedBorder {...JSON.parse(block.attrs.slanted_border)} />}
    </section>
  )
}