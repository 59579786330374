import parse from 'html-react-parser'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from "react-router-dom"
import { Context } from '../../../context'
import styles from './contact.module.scss'

type ContactProps = {
  block: Record<string, any>
  scroll: number
}

export default ({ block, scroll }: ContactProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState<boolean>(false)
  const context = useContext(Context)

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5) &&
      ref.current.getBoundingClientRect().bottom > (window.innerHeight * .25)) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
  }, [scroll])

  return (
    <section className={styles.contact + ` ${show ? styles.show : ''}`} ref={ref}>
      <div className={styles.left}>
        <h2 className={styles.heading}>{block.attrs.heading}</h2>
        <p className={styles.address}>{parse(block.attrs.address)}</p>
        <p className={styles.email}>{block.attrs.email}</p>
        <p className={styles.phone}>{block.attrs.phone}</p>
        {block.attrs.cta_text &&
        <div className={styles.ctaContainer}>
          <button className={styles.cta} onClick={() => {
            context && context.setDropdownOpen(true)
          }}>
            <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
            <div className={styles.arrow} />
          </button>
        </div>}
      </div>
      <div className={styles.right}>
        <img src={block.attrs.image_url} alt={block.attrs.alt_text} />
      </div>
    </section>
  )
}