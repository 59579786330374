import parse from 'html-react-parser'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './tabsTextImage.module.scss'

type TabsTwoColumnsProps = {
  block: Record<string, any>
  scroll: number
}

type Tab = {
  label: string
  content: string
  image_url: string
  link: string
  image_alt_text: string
  cta_text: string
  cta_link: string
}

export default ({ block, scroll }: TabsTwoColumnsProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const tabs = block.attrs.tabs
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0])
  const [showContent, setShowContent] = useState<boolean>(false)
  const colorScheme = block.attrs.color_scheme

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && (ref.current.getBoundingClientRect().top < 200 ||
        ref.current.getBoundingClientRect().bottom < window.innerHeight))
      setShow(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < (window.innerHeight * .5)) {
        setShow(true);
      }
    }
  }, [scroll])

  useEffect(() => {
    setShowContent(false);
    const timer = setTimeout(() => { setShowContent(true)}, 50)
    return (() => clearTimeout(timer))
  }, [activeTab])

  const scrollDown = () => {
    window.scrollTo({ top: (window.innerHeight * .85) + window.scrollY, behavior: 'smooth' })
  }

  return (
    <section className={styles.tabsTextImage + ` ${show ? styles.show : ''}` +
    ` ${colorScheme ? styles.light : ''}`} ref={ref}>
      <div className={styles.left}>
        <h2 className={styles.heading}>{block.attrs.heading}</h2>
        {tabs && tabs.map((tab: Tab, i: number) => (
          <button
            key={i}
            onClick={() => setActiveTab(tab)}
            className={styles.tab + ` ${activeTab.label === tab.label ? styles.active: ""}`}
            aria-label={`View ${tab.label}`}
            >
            {tab.label}
          </button>
        ))}
      </div>
      <div className={styles.right}>
        <div className={styles.rightText + ` ${!showContent ? styles.hide : ""}`}>
          {parse(activeTab.content)}
        </div>
        {activeTab.cta_text &&
        <div className={styles.ctaContainer}>
          {activeTab.cta_link?.startsWith("/") &&
          <Link className={styles.button} to={activeTab.cta_link}>
            <p className={styles.buttonLabel}>{activeTab.cta_text}</p>
            <div className={styles.arrow} />
          </Link>}
          {!activeTab.cta_link &&
          <button className={styles.button} onClick={scrollDown}>
            <p className={styles.buttonLabel}>{activeTab.cta_text}</p>
            <div className={styles.arrow} />
          </button>}
          {activeTab.cta_link && !activeTab.cta_link?.startsWith("/") &&
          <a className={styles.button} href={activeTab.cta_link} target="_blank" rel="noopener noreferrer">
            <p className={styles.buttonLabel}>{activeTab.cta_text}</p>
            <div className={styles.arrow} />
          </a>}
        </div>}
      </div>
      <div className={styles.image}>
        {activeTab.link?.startsWith("/") &&
        <Link to={activeTab.link}>
          <img src={activeTab.image_url} alt={activeTab.image_alt_text} />
        </Link>}
        {!activeTab.link?.startsWith("/") &&
        <a href={activeTab.link} target="_blank" rel="noopener noreferrer">
          <img src={activeTab.image_url} alt={activeTab.image_alt_text} />
        </a>}
        <div className={styles.arrow} />
      </div>
      <div className={styles.cubeOne + ` ${colorScheme ? styles.light : ''}`} />
      <div className={styles.cubeTwo + ` ${colorScheme ? styles.light : ''}`} />
    </section>
  )
}