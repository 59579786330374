import React from 'react'

import Layout from '../../layout'

import styles from './archive.module.scss'

export default (props: any) => {
  return (
    <Layout>
      <div className={styles.post}>
        <p>this is the page component</p>
        <p>this is the slug: {props.match.params.slug}</p>
      </div>
    </Layout>
  )
}
