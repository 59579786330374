import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { trimLink } from "../../../../utilities/trimLink";
import { MainLink, SubPage } from "../../header";
import styles from "./megaLink.module.scss";

type linkMenuProps = {
  link: MainLink
  menuOpen: boolean
  setMenuOpen: React.Dispatch<boolean>
}

export default ({ link, menuOpen, setMenuOpen,  }: linkMenuProps) => {
  const [linksOpen, setLinksOpen] = useState<boolean>(false)
  const [currentPageGroup, setCurrentPageGroup] = useState<number | null>(null)

  useEffect(() => {
    if (!isSubPage()) {
      setCurrentPageGroup(null)
      setLinksOpen(false)
    }
  }, [menuOpen])

  const hasActiveSubPage = (option: SubPage) => {
    const location = window.location.pathname
    let isActive = false
    if (option.is_page_group && option.pages) {
      option.pages.forEach(page => {
        if (location !== '/' && page.page.includes(location)) {
          isActive = true
        }
      })
    }

    return isActive
  }

  const handleClick = (index: number) => {
    if (currentPageGroup === index) {
      setCurrentPageGroup(null)
    } else {
      setCurrentPageGroup(index)
    }
  }
  const parseLink = (path: string | undefined) => {
    if (path) {
      const parsedPath = path.replace(window.location.origin, '').split('-')
      return parsedPath[0]
    }
    return ''
  }

  const isSubPage = () =>
    parseLink(link.page) === parseLink(window.location.pathname)

  const calculateSubPagesHeight = () => {
    let subOptionCount : number = 0
    if (link?.sub_pages && currentPageGroup) {
      const subOptions = link.sub_pages[currentPageGroup].pages
      subOptionCount = subOptions?.length ?? 0
    }
    return subOptionCount + 30
  }

  const calculateHeight = () => {
    const length = link?.sub_pages?.length ?? 0
    return (length * 6) + calculateSubPagesHeight() + 30
  }

  return (
    <div
      className={styles.megamenu}
    >
      <NavLink
        className={styles.link + 
          ` ${isSubPage() ? styles.active : ''}`}
        activeClassName={styles.active}
        to={link?.page ? trimLink(link.page) : ''}
        onClick={e => { e.preventDefault(); setLinksOpen(!linksOpen) }}
      >
        {link.label}
      </NavLink>
      <div
        className={styles.megaLinks}
        style={{
          maxHeight: linksOpen
            ? `${calculateHeight()}vw`
            : 0
        }}
      >
        {link?.sub_pages && link.sub_pages.map((option, optionIndex) => (
          <div key={option.label}>
             {option?.label !== "Catalog" ? (
            !option.is_page_group ?
              <NavLink
                to={option?.page ? trimLink(option.page) : ''}
                key={optionIndex}
                className={styles.option}
                activeClassName={styles.active}
                onClick={() => { setMenuOpen(false) }}>
                {option.label}
              </NavLink> :
              <div className={styles.pageGroup}>
                <button
                  className={styles.option}
                  onClick={() => handleClick(optionIndex)}>
                  {option.label}
                </button>
                <div
                  className={styles.subOptions +
                  ` ${currentPageGroup === optionIndex || hasActiveSubPage(option) ?
                    styles.show : ''}`}
                  style={{
                    maxHeight: currentPageGroup === optionIndex || hasActiveSubPage(option)
                      ? `${calculateSubPagesHeight()}vw`
                      : 0
                  }}
                >
                  {option.pages && option.pages.map((page, subOptionIndex) => (
                    <NavLink
                      to={page?.page ? trimLink(page.page) : ''}
                      key={subOptionIndex}
                      className={styles.subOption +
                      ` ${currentPageGroup === optionIndex || hasActiveSubPage(option) ?
                        styles.show : ''}`}
                      activeClassName={styles.active}
                      onClick={() => setMenuOpen(false)}>
                      <span>{page.label}</span>
                    </NavLink>
                  ))}
                </div>
              </div>
             ) : (
              <a className={styles.option} href={option?.url}>{option.label}</a>
             )}
          </div>
        ))}
      </div>
    </div>
  )
}