import apiFetch from '@wordpress/api-fetch'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { getPath } from '../../../utilities/getPath'
import SlantedBorder from '../slantedBorder'
import styles from './leadership.module.scss'

type LeadershipProps = {
  block: Record<string, any>
  scroll: number
}

type Leadership = {
  index: string;
  name: string
  description: string
  email: string
  phone: string
  link: string
  image: string
  date: string
}

export default ({ block, scroll }: LeadershipProps) => {
  const [show, setShow] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const [leadership, setLeadership] = useState<any>([])
  const [current, setCurrent] = useState<number | null>(null)

  useEffect(() => {
    apiFetch({ path: `${getPath()}/wp-json/wp/v2/leadership?per_page=100`}).then(res => setLeadership(res))

    setTimeout(() => {
      if (ref.current && ref.current.getBoundingClientRect().top < 200)
      setShow(true)
    }, 50)
  }, [])

  const sorted = [...leadership].sort((a: Leadership, b: Leadership) => b.index > a.index ? -1 : 1)

  return (
    <section className={styles.leaders} ref={ref}>
      <div className={styles.left}>
        {sorted && sorted.map((leader: Leadership, i: number) => (
          <div key={i} className={styles.leader + ` ${current === i ? styles.show : ""}`}>
            <button
              className={styles.button}
              onClick={() => setCurrent(i)}
              >{leader.name}</button>
            <div className={styles.details}>
              <p className={styles.description}>{leader.description}</p>
              <p className={styles.email}>{leader.email}</p>
              <p className={styles.phone}>{leader.phone}</p>
              {leader.link?.startsWith("/") &&
              <Link className={styles.ctaContainer} to={leader.link}>
                <button className={styles.cta}>
                  <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
                  <div className={styles.arrow} />
                </button>
              </Link>}
              {!leader.link?.startsWith("/") &&
              <a className={styles.ctaContainer} href={leader.link} target="_blank" rel="noopener noreferrer">
                <button className={styles.cta}>
                  <p className={styles.buttonLabel}>{block.attrs.cta_text}</p>
                  <div className={styles.arrow} />
                </button>
              </a>}
            </div>
          </div>
        ))} 
      </div>
      <div className={styles.right}>
        {sorted && sorted.map((leader: Leadership, i: number) => (
          <img
            key={i}
            src={leader.image}
            className={styles.image + ` ${current === i ? styles.show : ''}`}
            alt=""
          />
        ))}
        <img
          src={block.attrs.default_image_url}
          className={styles.image + ` ${current === null ? styles.show : ''}`}
          alt=""
        />
      </div>
      {block.attrs.has_slanted_border && block.attrs.slanted_border &&
      <SlantedBorder {...JSON.parse(block.attrs.slanted_border)} />}
    </section>
  )
}