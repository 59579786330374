import React from "react";
import { NavLink } from "react-router-dom";
import { MainLink } from "../../header";
import styles from "./menuButton.module.scss";

type props = {
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<boolean>;
};

export default ({ menuOpen, setMenuOpen }: props) => {
  return (
    <button
      onClick={() => setMenuOpen(!menuOpen)}
      className={styles.menu}
      aria-label="Menu Button"
    ></button>
  );
};
