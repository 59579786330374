import React, { useEffect, useRef } from "react";
import styles from "./embedForm.module.scss";

type EmbedFormProps = {
  block: Record<string, any>;
};

export default ({ block }: EmbedFormProps) => {
  const contentRef = useRef<any[]>([]);

  useEffect(() => {
    const fragment = document
      .createRange()
      .createContextualFragment(` <div>${block.attrs.code}</div>`);
    if (contentRef.current && contentRef.current[0]) {
      contentRef.current[0].innerHTML = "";
      contentRef.current[0].appendChild(fragment);
    } else {
      return;
    }
  }, [contentRef, block]);

  return (
    <section className={styles.embedForm}>
      <h3 className={styles.heading}>{block.attrs.heading}</h3>
      <div
        className={styles.maFormContainer}
        ref={(el) => (contentRef.current[0] = el)}
      />
    </section>
  );
};
