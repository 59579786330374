import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SlantedBorder from "../slantedBorder";
import styles from "./cta.module.scss";

type CTAProps = {
  block: Record<string, any>;
  scroll: number;
};

export default ({ block, scroll }: CTAProps) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (
        ref.current &&
        (ref.current.getBoundingClientRect().top < 200 ||
          ref.current.getBoundingClientRect().bottom < window.innerHeight)
      )
        setShow(true);
    }, 50);
  }, []);

  useEffect(() => {
    if (scroll && ref.current) {
      if (ref.current.getBoundingClientRect().top < window.innerHeight * 0.5) {
        setShow(true);
      }
    }
  }, [scroll]);

  return (
    <section className={styles.cta + ` ${show ? styles.show : ""}`} ref={ref}>
      <div
        role="img"
        aria-label={block.attrs.background_image_alt_text}
        className={styles.backgroundImage}
        style={{ backgroundImage: `url(${block.attrs.background_image_url})` }}
      />
      <div className={styles.overlay} />
      <h2
        className={styles.heading + ` ${!block.attrs.body ? styles.big : ""}`}
      >
        {block.attrs.heading}
      </h2>
      {block.attrs.body && <p className={styles.body}>{block.attrs.body}</p>}
      {block.attrs.label && (
        <>
          {block.attrs.button_link ? (
            <>
              {block.attrs.button_link.startsWith("/") && (
                <Link className={styles.button} to={block.attrs.button_link}>
                  <p className={styles.buttonLabel}>{block.attrs.label}</p>
                  <div className={styles.arrow} />
                </Link>
              )}
              {block.attrs.button_link &&
                !block.attrs.button_link.startsWith("/") && (
                  <a
                    className={styles.button}
                    href={block.attrs.button_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`${block.attrs.label}`}
                  >
                    <p className={styles.buttonLabel}>{block.attrs.label}</p>
                    <div className={styles.arrow} />
                  </a>
                )}
            </>
          ) : (
            <button
              className={styles.button}
              onClick={() =>
                window.scrollTo(0, window.innerHeight * 0.75 + window.scrollY)
              }
            >
              <p className={styles.buttonLabel}>{block.attrs.label}</p>
              <div className={styles.arrow} />
            </button>
          )}
        </>
      )}
      {block.attrs.has_slanted_border && block.attrs.slanted_border && (
        <SlantedBorder {...JSON.parse(block.attrs.slanted_border)} />
      )}
      {block.attrs.large_text && (
        <div
          className={styles.largeText}
          style={{
            transform: `translateX(${0 - scroll * 0.1}vw)`,
          }}
        >
          {block.attrs.large_text}
        </div>
      )}
    </section>
  );
};
