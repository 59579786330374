import React from "react";
import { NavLink } from "react-router-dom";
import { trimLink } from "../../../../utilities/trimLink";
import { MainLink } from "../../header";
import styles from "./topLinks.module.scss";

type LinkProp = {
  label: string;
  page: string;
  url?: string;
};

type TopLinksProps = {
  links: LinkProp[];
  setMega: React.Dispatch<MainLink | null>;
};

const ToLink: React.FC<{
  link: LinkProp;
  setMega: React.Dispatch<MainLink | null>;
}> = ({ link, setMega }) => {
  return link.url ? (
    <a
      className={styles.link}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => setMega(null)}
      href={link.url}
      aria-label={`${link.label} Link`}
    >
      {link.label}
    </a>
  ) : (
    <NavLink
      className={styles.link}
      activeClassName={styles.active}
      onClick={() => setMega(null)}
      to={trimLink(link.page)}
    >
      {link.label}
    </NavLink>
  );
};

export default ({ links, setMega }: TopLinksProps) => {
  return (
    <div className={styles.topLinks}>
      {links &&
        links.map((link, i) => <ToLink key={i} {...{ link, setMega }} />)}
    </div>
  );
};
